import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faVolumeHigh, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import '../../styling/Header.css';
import { useApiClient } from '../../service/API';
import { useWebSocketContext } from '../WebSocket/WebSocketContext';

function Header({ onLogout }) {
  const navigate = useNavigate();
  const { expiration, authenticated, userEmail, userName, userRoles, loading, handleLogout, handleLogin, mute, setMute } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [msgAlerts, setMsgAlerts] = useState([]);
  const apiClient = useApiClient();

  console.log(mute);
  //onMessageReceivedForAlert
  const {
    isConnected,
    stompClient,
    message,
    messageRead,
    alertMessage,
    mainRefresh,
    setMainRefresh,
    manageUserRefresh
   } = useWebSocketContext();

  const updateMute = async (e) => {
    e.preventDefault();
    try{
      const response = await apiClient.patch('setting/mute');
      if(response.status === 200){
        setMute(response.data);
      }
    }catch (error) {
      // console.error(error.response?.data);
    }

  }

  const fetchHistoryNotification = useCallback(async () => {
    try {
      const response = await apiClient.get(`tickets/notify/notification`);
      const historyMsg = response.data;
      setAlerts(historyMsg.filter(msg => !(['LEAVE','LEAVE-REQUEST'].includes(msg.type) && msg.messageRead)));
    } catch (error) {
      // console.error(error.response?.data?.businessExceptionDescription || 'Error fetching ticket data. Please try again.');
    }
  }, [apiClient]);

  const fetchHistoryMsgNotification = useCallback(async () => {
    try {
      const response = await apiClient.get(`tickets/notify/msg-notification`);
      const historyMsg = response.data;
      setMsgAlerts(historyMsg);
    } catch (error) {
      // console.error(error.response?.data?.businessExceptionDescription || 'Error fetching ticket data. Please try again.');
    }
  }, [apiClient]);

  useEffect(() => {
    fetchHistoryNotification();
    fetchHistoryMsgNotification();
  }, []);

  function onMessageReceivedForAlert(msg) {
    if(!msg){
      return;
    }

    // Handle message type 'MESSAGE'
    if (msg.type === 'MESSAGE') {
      // Show success toast for TicketId if present
      if (msg.ticketId !== null) {
        if(!mute){
          const audio = new Audio("/sound/notification.mp3");
          audio.play().catch((error) => {
            console.log("Audio play failed: ", error);
          });
        }
        toast.success(`Alert received for TicketId: ${msg.ticketId}`);
      }
      setMsgAlerts((prevAlerts) => [msg, ...prevAlerts]);

    } else {
      // Handle other message types
      if(['PENDING', 'URGENT', 'ERROR', 'NEW', 'COMPLETED', 'REWORK', 'CANCELLED','LEAVE','LEAVE-REQUEST'].some(status => status === msg.type)) {
        if (msg.ticketId !== null) {
          if(!mute){
            const audio = new Audio("/sound/notification.mp3");
            audio.play().catch((error) => {
              console.log("Audio play failed: ", error);
            });
          }
          toast.success(`Alert received for TicketId: ${msg.ticketId}`);
        }

        if((msg.type === 'LEAVE' || msg.type === 'LEAVE-REQUEST') && msg.messageRead){
          return;
        }else{
          setAlerts((prevAlerts) => {
            // Check if the message with the same msgId already exists in the alerts
            const isDuplicate = prevAlerts.some(alert => alert.msgId === msg.msgId);

            // If it's not a duplicate, add the new message to the front
            if (!isDuplicate) {
              return [msg, ...prevAlerts];
            }
            // If it's a duplicate, return the previous alerts unchanged
            return prevAlerts;
          });
        }
      }else{
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert.msgId === msg.id ? { ...alert, messageRead: msg.messageRead } : alert
          )
        );
      }
    }
  }

  useEffect(()=>{
    if(alertMessage){
      onMessageReceivedForAlert(alertMessage);
    }
    if(messageRead){
      onMessageReceivedForAlert(messageRead);
    }
  },[alertMessage, messageRead])

  const handleClick = async (ticketId) => {
    // Find the message to update
    const updatedMsgAlerts = msgAlerts.map((msg) => {
      if (msg.ticketId === ticketId && msg.msgId) {
        // Perform API call to update the read status
        apiClient.put(`/tickets/chat/update-read-status/${msg.msgId}`).catch((error) => {
          // console.error('Error updating read status:', error);
        });
        // Return the updated message
        return { ...msg, messageRead: true }; // Assuming 'read' is the property you want to set
      }
      return msg; // Return the original message if not updated
    });

    // Update state and navigate
    setMsgAlerts(updatedMsgAlerts);
    navigate(`/view-ticket/${ticketId}`);
  };

  const handleClickTicket = (ticketId, msgId, status) => {
    // Find the message to update
    const updatedMsgAlerts = alerts.map((alert) => {
      if (alert.msgId === msgId) {
        // Perform API call to update the read status
        apiClient.put(`/tickets/notify/update-read-ticketStatus/${alert.msgId}`).catch((error) => {
          // console.error('Error updating read status:', error);
        });
        // Return the updated message
        return { ...alert, messageRead: true }; // Assuming 'read' is the property you want to set
      }
      return alert; // Return the original message if not updated
    });

    // Update state and navigate
    setAlerts(updatedMsgAlerts);
    if(ticketId){
      navigate(`/view-ticket/${ticketId}`);
    }

    if(status==='LEAVE-REQUEST'&&['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))){
      navigate('/manage-leave');
    }
  };

  useEffect(() => {
    // Dismiss all toasts when navigating away from the current page
    toast.dismiss();
  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-2">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* Logo */}
          <a className="navbar-brand" href="/home">
            <img src="/image/logo.png" alt="Logo" style={{ height: '40px' }} />
          </a>

          {/* Navbar Links */}
          {authenticated && (
            <ul className="navbar-nav d-flex flex-row mb-0">
              <li className="nav-item me-3">
                <a className="nav-link active" aria-current="page" href="/home">Home</a>
              </li>
              {userRoles.includes("ADMIN") && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/assign-role">Assign Role</a>
                </li>
              )}
              {['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/performance-report">Performance Report</a>
                </li>
              )}
              {['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/manage-leave">Manage Leave</a>
                </li>
              )}
              {['ADMIN'].some(role => userRoles.includes(role)) && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/holiday">Manage Holiday</a>
                </li>
              )}
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link"
                  role="button"
                  data-bs-toggle={alerts.length > 0 || msgAlerts.length > 0 ? "dropdown" : undefined}
                  aria-expanded={alerts.length > 0 || msgAlerts.length > 0}
                  href="#"
                >
                  <FontAwesomeIcon icon={faBell} />
                  {(
                    alerts.filter(alert => !alert.messageRead).length > 0 ||
                    msgAlerts.filter(alert => !alert.messageRead).length > 0
                  ) && (
                    <span className="badge bg-danger">
                      {(
                        alerts.filter(alert => !alert.messageRead).length +
                        msgAlerts.filter(alert => !alert.messageRead).length
                      )}
                    </span>
                  )}
                </a>
                {(alerts.length > 0 || msgAlerts.length > 0) && (
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><h6 className="dropdown-header">Ticket Notification</h6></li>
                    <li><hr className="dropdown-divider" /></li>
                      <React.Fragment>
                        {alerts.map((alert, index) => (
                          <li key={`${alert.ticketId}-${index}`}> {/* Unique key for alert */}
                            <a className={`dropdown-item ${alert.messageRead ? '' : alert.type} alert`}
                            onClick={(e)=>{
                              e.preventDefault();
                              handleClickTicket(alert.ticketId, alert.msgId, alert.type);
                            }}
                            href={
                              alert.ticketId ? `/view-ticket/${alert.ticketId}` :
                              alert.type==='LEAVE-REQUEST'&&['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) ? '/manage-leave'
                              : "#"
                            }
                            >
                              {['LEAVE','LEAVE-REQUEST'].includes(alert.type)? `Leave Notification` :`Ticket ID ${alert.ticketId}`}:
                              <br />{alert.message}
                            </a>
                          </li>
                        ))}
                      </React.Fragment>

                    <li><hr className="dropdown-divider" /></li>
                    <li><h6 className="dropdown-header">Message Notification</h6></li>
                    <li><hr className="dropdown-divider" /></li>
                    {msgAlerts.filter(alert => alert.type === "MESSAGE").map((alert, index) => (
                      !alert.messageRead &&
                      <li key={`${alert.ticketId}-${index}`}> {/* Unique key for msgAlerts */}
                        <a className={`dropdown-item MESSAGE alert`}
                          onClick={(e) => {
                            // Prevent the default action of opening the link in a new tab
                            e.preventDefault();
                            handleClick(alert.ticketId);
                          }}
                          href={`/view-ticket/${alert.ticketId}`} >
                          Ticket ID {alert.ticketId}:
                          <br />{alert.message}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  User
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><a href="#" className="dropdown-item disabled">{userName}</a></li>
                  <li><a href="#" className="dropdown-item disabled">{userEmail}</a></li>
                  {['BOUSER', 'ADMIN'].some(role => userRoles.includes(role)) &&
                    <li><a className="dropdown-item" href="/leave">Leave</a></li>
                  }
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const confirmLogout = window.confirm("Are you sure you want to log out?");
                        if (confirmLogout) {
                          onLogout();
                          navigate('/login');
                        }
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
              <li className='nav-itme me-3' onClick={updateMute}>
                {mute ?
                <FontAwesomeIcon icon={faVolumeXmark} />
                :
                <FontAwesomeIcon icon={faVolumeHigh} />}
              </li>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Header;

import React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TicketList from "../TicketListComponents/TicketList";
import { useApiClient } from "../../service/API";
import LeaveList from "./LeaveList";
import { useWebSocketContext } from "../WebSocket/WebSocketContext";
import "../../styling/Leave.css"

export default function ManageLeavePage(){
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const [leaveRequestList, setLeaveRequestList] = useState([]);
    const [onLeavelist, setOnLeaveList] = useState([]);
    const [leaveRequestListLoading, setLeaveRequestListLoading] = useState(false);
    const [onLeaveListLoading, setOnLeaveListLoading] = useState(false);

    const {
        isConnected,
        stompClient,
        message,
        messageRead,
        alertMessage,
        mainRefresh,
        setMainRefresh,
        manageUserRefresh,
    } = useWebSocketContext();

    const fetchLeaveRequestList = async () => {
        setLeaveRequestListLoading(true);
        const response = await apiClient.get('/users/leave-request-list');
        if(response.status === 200){
            setLeaveRequestList(response.data);
            setLeaveRequestListLoading(false);
        }
    };

    const fetchonLeaveList = async () => {
        setOnLeaveListLoading(true);
        const response = await apiClient.get('/users/onleave-list');
        if(response.status === 200){
            setOnLeaveList(response.data);
            setOnLeaveListLoading(false);
        }
    };

    useEffect(() => {
        fetchLeaveRequestList();
        fetchonLeaveList();
    },[]);

    useEffect(()=>{
        if(manageUserRefresh){
            fetchLeaveRequestList();
            fetchonLeaveList();
        }
        if(alertMessage){
            if(alertMessage.type === 'LEAVE'){
                fetchLeaveRequestList();
                fetchonLeaveList();
            }
        }
    },[manageUserRefresh, alertMessage]);

    const onApprovalButton = async (id, status) => {
        try {
            // Send the patch request with query parameters
            await apiClient.patch(`/users/leave-request`, null, {
                params: {
                    status: status,  // Query parameter for status
                    leaveId: id      // Query parameter for leaveId
                }
            });

        } catch (error) {
            console.error('Error during approval:', error.response ? error.response.data : error.message);
        }
    };


    return(
        <div>
            <div className="leave-container">
                <br/>
                <div>
                    <button className="btn btn-success ms-3" onClick={(e) => {e.preventDefault(); navigate("/leave")}}>BOUSER Leave Apply</button>
                </div>
                <br/>
                <div className="leave-table-1">
                    <LeaveList leavelist={leaveRequestList} loading={leaveRequestListLoading} title="request" onApprovalButton={onApprovalButton} />
                </div>
                <div className="leave-table-2">
                    <LeaveList leavelist={onLeavelist} loading={onLeaveListLoading} title="leave" />
                </div>
                <div className="leave-ticket-table">
                    <TicketList title='leave'/>
                </div>
            </div>
        </div>
    );
};
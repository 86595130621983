import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { API_BASE_URL, ACCESS_TOKEN } from '../../constants';
import { useAuth } from '../../auth/AuthHandler';

// Create WebSocket context
const WebSocketContext = createContext();

export const useWebSocketContext = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [stompClient, setStompClient] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageRead, setMessageRead] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [pendingAlert, setPendingAlert] = useState([]);
  const { authenticated, userEmail, handleLogout } = useAuth();
  const [ticketId, setTicketId] = useState(null);
  const [mainRefresh, setMainRefresh] = useState(false);
  const [manageUserRefresh, setManageUserRefresh] = useState(null);
  const location = useLocation();

  const socketRef = useRef(null);
  const clientRef = useRef(null);
  const subscriptionsRef = useRef({});
  const heartbeatIntervalRef = useRef(null);

  // Extract ticketId from URL
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length > 2 && pathSegments[1] === 'view-ticket') {
      setTicketId(pathSegments[2]);
    } else {
      setTicketId(null);
    }
  }, [location]);

  // Update connection status helper
  const updateConnectionStatus = (status) => {
    if (isConnected !== status) {
      setIsConnected(status);
    }
  };

  // Cleanup subscriptions
  const cleanupSubscriptions = () => {
    Object.values(subscriptionsRef.current).forEach((sub) => sub?.unsubscribe());
    subscriptionsRef.current = {};
  };

  // Cleanup WebSocket connection
  const disconnectWebSocket = () => {
    if (clientRef.current) {
      cleanupSubscriptions();
      clientRef.current.disconnect();
      clientRef.current = null;
      setStompClient(null);
    }
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
    updateConnectionStatus(false);
  };

  // Connect WebSocket with improved logic
  const connectWebSocket = () => {
    if (isConnecting || clientRef.current?.connected) return;

    setIsConnecting(true);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!API_BASE_URL || !token) {
      setIsConnecting(false);
      console.error('API_BASE_URL or access token is missing.');
      return;
    }

    disconnectWebSocket(); // Ensure no stale connections

    const socket = new SockJS(`${API_BASE_URL}/chat?token=${token}`);
    const client = Stomp.over(() => socket);

    client.heartbeatIncoming = 4000;
    client.heartbeatOutgoing = 4000;
    client.reconnectDelay = 1000; // Auto-reconnect with 5s delay

    client.onConnect = (frame) => {
      setIsConnecting(false);
      updateConnectionStatus(true);

      cleanupSubscriptions();

      // Subscribe to user alerts
      if (userEmail) {
        subscriptionsRef.current.alert = client.subscribe(
          `/topic/alert/${userEmail}`,
          (msg) => {
            const data = JSON.parse(msg.body);
            if (data.type === 'REFRESH-LEAVE') {
              setManageUserRefresh(data);
            } else {
              setAlertMessage(data);
              if (data.type === 'PENDING') {
                setPendingAlert((prev) => [...prev, data]);
              }
            }
          }
        );
      }

      // Subscribe to ticket messages
      if (ticketId) {
        subscriptionsRef.current.ticket = client.subscribe(
          `/topic/ticket/${ticketId}`,
          (msg) => setMessage(JSON.parse(msg.body))
        );
        subscriptionsRef.current.msgRead = client.subscribe(
          `/topic/msg-read/${ticketId}`,
          (msg) => setMessageRead(JSON.parse(msg.body))
        );
      }

      // Subscribe to refresh events
      subscriptionsRef.current.refresh = client.subscribe(`/topic/refresh`, (msg) => {
        const data = JSON.parse(msg.body);
        if (data.type === 'REFRESH-HOME') {
          setMainRefresh(true);
        }
      });

      // Manual heartbeat to keep connection alive
      heartbeatIntervalRef.current = setInterval(() => {
        if (client.connected) {
          client.send('/app/heartbeat', {}, '{}'); // Adjust endpoint as per your server
        } else {
          console.warn('Heartbeat failed, connection lost. Reconnecting...');
          disconnectWebSocket();
          connectWebSocket();
        }
      }, 5000); // Check every 5 seconds
    };

    client.onStompError = (error) => {
      console.error('STOMP error:', error);
      updateConnectionStatus(false);
      setIsConnecting(false);
    };

    client.onWebSocketClose = () => {
      console.log('WebSocket closed. Attempting to reconnect...');
      updateConnectionStatus(false);
    };

    client.activate();
    socketRef.current = socket;
    clientRef.current = client;
    setStompClient(client);
  };

  // Manage connection lifecycle
  useEffect(() => {
    if (!authenticated) {
      disconnectWebSocket();
      return;
    }

    connectWebSocket();

    return () => disconnectWebSocket();
  }, [authenticated, ticketId, userEmail]);

  // Handle visibility and network changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!clientRef.current?.connected) {
          console.log('Tab visible, forcing reconnect...');
          connectWebSocket();
        }
      }
    };

    const handleOnline = () => {
      console.log('Browser online, ensuring connection...');
      if (!clientRef.current?.connected) {
        connectWebSocket();
      }
    };

    const handleFocus = () => {
      console.log('Browser focus, ensuring connection...');
      if (!clientRef.current?.connected) {
        connectWebSocket();
      }
    };

    const handleOffline = () => {
      console.log('Browser offline, pausing reconnection...');
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Handle closing pending notifications
  const handleClosePendingNotification = (msgId) => {
    setPendingAlert((prev) => prev.filter((n) => n.msgId !== msgId));
  };

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        stompClient,
        message,
        messageRead,
        alertMessage,
        mainRefresh,
        setMainRefresh,
        manageUserRefresh
      }}
    >
      <div>
        {isConnecting && <div>Connecting...</div>}
        {children}
        {pendingAlert.length > 0 && (
          <div className="pending-notifications-list">
            <ul>
              {pendingAlert.map((notification) => (
                <li key={notification.msgId}>
                  <div className="pending-notification-item">
                    <p>{notification.message}</p>
                    <button
                      onClick={() => handleClosePendingNotification(notification.msgId)}
                      className="btn-close"
                    >
                      X
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </WebSocketContext.Provider>
  );
};
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../../service/API';
import FileUpload from '../FileHandling/FileUpload';
import FileList from '../FileHandling/FileList';
import FileViewer from '../FileHandling/FileViewer';
import NotificationModal from './NotificationModal';
import ConfirmationModal from './ConfirmationModal';
import FileUploadingModal from './FileUploadingModal';
import SearchableDropdown from './SearchableDropdown';
import Modal from './Modal';
import { useAuth } from '../../auth/AuthHandler';

function TicketForm({ title, ticketInfo, ticketId }) {
  const { userRoles } = useAuth();
  const navigate = useNavigate();
  const [remark, setRemark] = useState('');
  const [ticketStatus, setTicketStatus] = useState('NEW');
  const [ticketStage, setTicketStage] = useState('');
  const [sysFreightRef, setSysFreightRef] = useState('');
  const [assignTo, setAssignTo] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [ticketFrom, setTicketFrom] = useState('');
  const [reworkTicketId, setReworkTicketId] = useState('');
  const [originalTicketId, setOriginalTicketId] = useState('');
  const [files, setFiles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [ticketFolderName, setTicketFolderName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({ remark: '', SysFreight: '', serviceType: '', ticketStage: ''});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showCompleteConfirmation, setShowCompleteConfirmation] = useState(false);
  const [showDoneConfirmation, setShowDoneConfirmation] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [onCreating, setOnCreating] = useState(false);
  const apiClient = useApiClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExpandClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
      if (title !== 'create-ticket' && ticketInfo) {
        setOriginalTicketId(ticketInfo.originalTicketId);
        setAssignTo(ticketInfo.assignedTo);
        setRemark(ticketInfo.remark);
        setTicketStatus(ticketInfo.status);
        setFiles(ticketInfo.docs.map(doc => ({
          name: doc.name,
          fileId: doc.fileId
         })));
        setTicketFolderName(ticketInfo.folderName);
        setCurrentTicketId(ticketInfo.id);
        setSysFreightRef(ticketInfo.sysFreightRef);
        setServiceType(ticketInfo.serviceType);
        setTicketStage(ticketInfo.stage);
        setSelectedFile(ticketInfo.docs[0]);
        setTicketFrom(ticketInfo.createdBy);
        setReworkTicketId(ticketInfo.reworkTicketId);
      }
      if(['BOMANAGER', 'FOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))){
        fetchUserList();
      }
  },[ticketId, ticketInfo])

  const handleUpdateButton = useCallback((id) => {
    navigate(`/update-ticket/${id}`);
  }, [navigate]);

  const handleApiError = (error, defaultMessage) => {
    const message = error.response?.data?.businessExceptionDescription || defaultMessage;
    setShowUpdateConfirmation(false);
    setShowCompleteConfirmation(false);
    setShowNotification(true);
    setNotificationMessage(message);
  };

  const fetchUserList = async () => {
    try {
      const userListGet = await apiClient.get(`/users/bouser-list`);
      setUserList(userListGet.data);
    } catch (error) {
      // handleApiError(error, 'Error fetching user list. Please contact IT support.');
    }
  };

  const handleTextAreaChange = (event) => {
    const { value } = event.target;
    setRemark(value);
    setErrors(prevErrors => ({ ...prevErrors, remark: '' }));
  };

  const removeFile = (name) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== name));
    setNotificationMessage('File deleted successfully');
    setShowNotification(true);
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if(!sysFreightRef.trim()){
        newErrors.SysFreight = 'Sysfreight Reference Number is required';
        isValid = false;
    }

    if (!remark) {
        newErrors.remark = 'Remark is required';
        isValid = false;
    }

    if (!serviceType) {
      newErrors.serviceType = 'Service Type is required';
      isValid = false;
    }

    if (!ticketStage.trim()) {
      newErrors.ticketStage = 'Ticket Stage is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    const jsonPayload = {
      remark,
      status: ticketStatus,
      docs: files.map(file => file.name) || [],
      folderName: ticketFolderName,
      assignToEmail: assignTo,
      stage: ticketStage,
      sysFreightRef: sysFreightRef,
      serviceType: serviceType
    };

    try {
      if (title === 'create-ticket') {
        setOnCreating(true);
        const response = await apiClient.post('/tickets/create-ticket', jsonPayload, {
          headers: { 'Content-Type': 'application/json' },
        });
        const { id, assignedTo } = response.data;
        setCurrentTicketId(id);
        setAssignTo(assignedTo);
        setNotificationMessage('Ticket created successfully!');
      }
    } catch (error) {
      handleApiError(error, 'Error saving data. Please contact IT support.');
    }
    finally{
      setShowNotification(true);
      setOnCreating(false);
    }
  };

  const handleUpdateTrigger = (event) => {
    event.preventDefault();
    if (title === 'update-ticket' && ticketId) {
      setShowUpdateConfirmation(true);
    }
  }

  const handleCancel = () => {
    if (['create-ticket', 'view-ticket'].includes(title)) {
        navigate('/home');
    } else {
        navigate(`/view-ticket/${ticketId}`);
    }
  };

  // error here override
  const handleModalClose = () => {
    setShowNotification(false);
    if(title === 'create-ticket' && ticketStatus === "PENDING" && notificationMessage==='Ticket created successfully!'){
      navigate(`/home`);
    }else if(title === 'update-ticket' && notificationMessage==='Ticket updated successfully!'){
      navigate(`/view-ticket/${currentTicketId}`);
    }else if(title === 'create-ticket' && notificationMessage==='Ticket created successfully!'){
      navigate(`/view-ticket/${currentTicketId}`);
    }else if(title === 'view-ticket' && ticketStatus === "DONE"){
      navigate(`/home`);
    }
  };

  const fetchFile = async (name, fileId) => {
    try {
      const response = await apiClient.get('/files/retrieve', {
        params: {
          fileId
        },
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json' }
      });

      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        throw new Error(`Server returned status: ${response.status}`);
      }
    } catch (err) {
      handleApiError(err, 'Unable to fetch the file.');
    }
  };

  const downloadAllFiles = async () => {
    for (const file of files) {
      await fetchFile(file.name, file.fileId);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    if (!validateFields()) {
      return;
    }

    const jsonPayload = {
      remark,
      status: ticketStatus,
      docs: files.map(file => file.name),
      folderName: ticketFolderName,
      assignToEmail: assignTo,
      stage: ticketStage,
      sysFreightRef: sysFreightRef,
      serviceType: serviceType
    };

    try {
      const response = await apiClient.put(`/tickets/update-ticket/${ticketId}`, jsonPayload, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { folderName, id } = response.data;
      setCurrentTicketId(id);
      setTicketFolderName(folderName);
      setNotificationMessage('Ticket updated successfully!');
      setShowUpdateConfirmation(false);
      setShowNotification(true);
    } catch (error) {
      handleApiError(error, 'Error updating data. Please contact IT support.');
    }
  };

  const handleComplete = async () => {
    setShowCompleteConfirmation(true);
  };

  const handleDone = async () => {
    setShowDoneConfirmation(true);
  }

  const confirmComplete = async (e) => {
    e.preventDefault();
    try {
      if(showCompleteConfirmation){
        await apiClient.patch(`/tickets/complete-ticket/${ticketId}`);
        setNotificationMessage('Ticket has been updated to COMPLETE!');
        setTicketStatus('COMPLETED');
      }else{
        handleApiError(null, 'Error completing ticket. Please contact IT support.');
      }
    } catch (error) {
      handleApiError(error, 'Error completing ticket. Please contact IT support.');
    } finally {
      setShowCompleteConfirmation(false);
      setShowNotification(true);
    }
  };

  const handleSelectionChange = (selectedEmail) => {
    setAssignTo(selectedEmail);
  };

  const handleApprovalButton = async (ticketId, status) => {
    try {
      // Send the patch request for approval
      await apiClient.patch(`/tickets/urgent-approval/${ticketId}/${status}`);
    } catch (error) {
      // Handle error if needed (optional: display an error message)
      // console.error('Error during approval:', error.response ? error.response.data : error.message);
    }
  };

  const handleDoneUpdate = async (e) =>{
    e.preventDefault();
    try {
      // Send the patch request for approval
      const response = await apiClient.patch(`/tickets/DONE/${ticketId}`);
      // After successful approval, trigger the ticket list refresh
      if(response.status === 200){
        setNotificationMessage(`Ticket ${ticketId} updated successful to DONE!`);
        setTicketStatus('DONE');
      }
    } catch (error) {
      // Handle error if needed (optional: display an error message)
      // console.error('Error during approval:', error.response ? error.response.data : error.message);
    }finally{
      setShowDoneConfirmation(false);
      setShowNotification(true);
    }
  };

  const formatEmailDomain = (email) => {
    // Extract the domain from the email address
    const domain = email.split('@')[1];

    // Check if the domain matches 'kaselog.com.sg'
    if (domain === 'kaselog.com.sg') {
        // Return the formatted string "KASE LOG"
        return 'KASE LOG';
    }

    if(domain === 'kasesin.com.sg'){
      return 'KASE LINE';
    }

    // If the domain is different, just return the domain
    return domain;
  }

  return (
    <div>
      <h1>{title === 'create-ticket' ? 'Create Ticket' : `Ticket ID: ${currentTicketId}`}</h1>
      <form>

        <div className="mb-3 row align-items-start">

          <div className='col'>
            <div className="mb-3 row align-items-end">
              <label htmlFor="sysFreightRef" className={`${(reworkTicketId || originalTicketId) ? "col-sm-5" : "col-sm-2"} col-form-label`}>SysFreight Reference Number:</label>
              <div className={`${(reworkTicketId || originalTicketId) ? "col-sm-6" : "col-sm-10"} col-form-label`}>
                <input
                  type="text"
                  readOnly={(title === 'view-ticket') && true}
                  className={(title === 'view-ticket') ? "form-control-plaintext" :`form-control ${errors.SysFreight ? 'is-invalid' : ''}`}
                  id="sysFreightRef"
                  value={sysFreightRef || ''}
                  onChange={(e) => setSysFreightRef(e.target.value)}
                  maxLength={255}  // Limit input to 25 characters
                />
                {errors.SysFreight && <div className="invalid-feedback">{errors.SysFreight}</div>}
              </div>
            </div>
          </div>

          { originalTicketId &&
          <div className='col'>
            <div className="mb-3 row align-items-end">
              <label htmlFor="originalTicketId" className="col-sm-4 col-form-label">Original Ticket Id:</label>
              <div className="col-sm-5">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control-plaintext sticky-top-input"
                  id="originalTicketId"
                  value={originalTicketId || ''}
                  maxLength={255}  // Limit input to 25 characters
                />
              </div>
            </div>
          </div>
          }

        { reworkTicketId &&
          <div className='col'>
            <div className="mb-3 row align-items-end">
              <label htmlFor="reworkTicketId" className="col-sm-4 col-form-label">Rework Ticket Id:</label>
              <div className="col-sm-5">
                <input
                  type="text"
                  readOnly={true}
                  className="form-control-plaintext sticky-top-input"
                  id="reworkTicketId"
                  value={reworkTicketId || ''}
                  maxLength={255}  // Limit input to 25 characters
                />
              </div>
            </div>
          </div>
          }
        </div>

        {title !== 'create-ticket' &&
        <div className="mb-3 row align-items-start">

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="ticket-from" className="col-sm-5 col-form-label">Ticket From:</label>
              <div className="col-sm-7">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="ticket-from"
                value={ticketFrom || ''}
              />
              </div>
            </div>
          </div>

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="domain" className="col-sm-5 col-form-label">Domain:</label>
              <div className="col-sm-7">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="domain"
                value={formatEmailDomain(ticketFrom) || ''}
              />
              </div>
            </div>
          </div>

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="assign-to" className="col-sm-5 col-form-label">Ticket Assign To:</label>
              <div className="col-sm-7">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="assign-to"
                value={assignTo || ''}
              />
              {(title !== 'view-ticket' ) && ['BOMANAGER', 'FOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) &&
              <SearchableDropdown
                id="aassign-to"
                options={userList}
                selectedValue={assignTo}
                onSelectedChange={handleSelectionChange}
                placeholder="Select user"
              />}
              </div>
            </div>
          </div>
        </div>}

        <div className="mb-3 row align-items-start">

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="serviceType" className="col-sm-5 col-form-label">Service Type:</label>
              <div className="col-sm-7">
                {(title === 'view-ticket') ?
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="serviceType"
                    value={serviceType || ''}
                  />
                :
                  <select
                    id="serviceType"
                    className={`form-select ${errors.serviceType ? 'is-invalid' : ''}`}
                    value={serviceType||''}
                    onChange={(e) => setServiceType(e.target.value)} // Changed from setTicketStage to setServiceType
                  >
                    <option value='' disabled>-- Select --</option>
                    {['CONSOLE', 'AIR', 'FCL', 'LCL'].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                }
                {errors.serviceType && <div className="invalid-feedback">{errors.serviceType}</div>}
              </div>
            </div>
          </div>

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="ticketStage" className="col-sm-5 col-form-label">Ticket Stage:</label>
              <div className="col-sm-7">
                {(title === 'view-ticket') ?
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="ticketStage"
                    value={ticketStage||''}
                  />
                :
                  <select
                    id="ticketStage"
                    className={`form-select ${errors.ticketStage ? 'is-invalid' : ''}`}
                    value={ticketStage||''}
                    onChange={(e) => setTicketStage(e.target.value)}
                  >
                    <option value='' disabled>-- Select --</option>
                    {
                      ['Booking Creation', 'HBL Creation', 'MBL Creation', 'Payment Request', 'Shipped On Board', 'Billing', 'Vendor Invoice']
                        .map((listValue) => {
                          return <option key={listValue} value={listValue}>{listValue}</option>;
                        })
                    }
                  </select>
                }
                {errors.ticketStage && <div className="invalid-feedback">{errors.ticketStage}</div>}
              </div>
            </div>
          </div>

          <div className='col'>
            <div className="mb-3 row">
              <label htmlFor="ticketStatus" className="col-sm-5 col-form-label">Ticket Status:</label>
              <div className="col-sm-7">
              {(title === 'view-ticket') ?
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="ticketStatus"
                  value={ticketStatus}
                />
              :
                <select
                  id="ticketStatus"
                  className="form-select"
                  value={ticketStatus||''}
                  onChange={(e) => setTicketStatus(e.target.value)}
                >
                  <option value="NEW">NEW</option>
                  <option value={title === 'update-ticket'? "URGENT" : "PENDING"}>URGENT - REQUIRED MANAGER APPROVAL</option>
                  {title === 'update-ticket' &&
                    <>
                      <option value="ERROR">ERROR</option>
                      <option value="REWORK">REWORK</option>
                    </>
                  }
                </select>
              }
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="inputRemark" className="col-sm-2 col-form-label">Remark:</label>
          <div className="col-sm-10">
            {title === 'view-ticket' && (
            <a href="#" onClick={handleExpandClick} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              Expand
            </a>
            )}
            <textarea
              name="remark"
              className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
              id="inputRemark"
              rows="3"
              value={remark}
              onChange={handleTextAreaChange}
              disabled={title === 'view-ticket'}
            />
            {errors.remark && <div className="invalid-feedback">{errors.remark}</div>}
          </div>
        </div>

        <div className="mb-3 row">
        <label htmlFor="file" className="col-sm-2 col-form-label">Files:</label>
        <div className="col-sm-10" id="file">
            {title !== 'view-ticket' && (
              <div>
                <FileList files={files} removeFile={removeFile} isUploading={fileUploading} folderName={ticketFolderName} />
                <FileUpload setFileUploading={setFileUploading} files={files} setFiles={setFiles} ticketFolderName={ticketFolderName} setTicketFolderName={setTicketFolderName} />
              </div>
            )}
            {title === 'view-ticket' && (
              <>
                <div className="row">
                  <div className='col-8'>
                    <select
                      id="fileList"
                      className="form-select"
                      value={selectedFile ? selectedFile.name : ''}
                      onChange={(e) => setSelectedFile(files.find(file => file.name === e.target.value))}
                      disabled={!files.length}
                    >
                      {files.map(file => (
                        <option key={file.name} value={file.name}>{file.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary" onClick={downloadAllFiles}>
                      Download All Files
                    </button>
                  </div>
                </div>
                {selectedFile && <FileViewer fileInfo={selectedFile} folderName={ticketFolderName} />}
              </>
            )}
          </div>
        </div>

        {(title === 'create-ticket') && (
          <div>
            <button type="button" className="btn btn-primary ms-2" onClick={handleSubmit}>CREATE</button>
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>CANCEL</button>
          </div>
        )}

        {(title === 'update-ticket')&& (
          <div>
            <button type="button" className="btn btn-primary" onClick={handleUpdateTrigger}>UPDATE</button>
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>CANCEL</button>
          </div>
        )}

        {title === 'view-ticket' && (
          <div>
            {((ticketStatus === 'URGENT' || ticketStatus === 'NEW' || ticketStatus === 'ERROR') && ['BOUSER', 'ADMIN'].some(role => userRoles.includes(role))) && (
              <button type="button" className="btn btn-primary ms-2" onClick={handleComplete}>
                COMPLETE
              </button>
            )}
            {((ticketStatus === 'COMPLETED') && ['FOUSER', 'FOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))) && (
              <button type="button" className="btn btn-primary ms-2" onClick={handleDone}>
                DONE
              </button>
            )}
            {(ticketStatus !== 'PENDING' && (((ticketStatus === 'URGENT' || ticketStatus === 'NEW' || ticketStatus === 'ERROR') && !['BOUSER'].some(role => userRoles.includes(role))) || (['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))))) && (
              <button type="button" className="btn btn-primary ms-2" onClick={()=>handleUpdateButton(currentTicketId)}>
                EDIT
              </button>
            )}
            {(['FOMANAGER', 'BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && ticketStatus === "PENDING") &&
            (
              <>
              <button className="btn btn-success ms-2" onClick={() => handleApprovalButton(ticketId, "APPROVED")}>APPROVE</button>
              <button className="btn btn-danger ms-2" onClick={() => handleApprovalButton(ticketId, "REJECTED")}>REJECT</button>
              </>
            )}
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>
              BACK TO HOME
            </button>
          </div>
        )}
      </form>

      {/* Notification Modal */}
      <NotificationModal
        show={showNotification}
        message={notificationMessage}
        onClose={handleModalClose}
      />
      <NotificationModal
        show={onCreating}
        message={"Creating"}
        onClose={null}
      />
      <ConfirmationModal
        show={showUpdateConfirmation}
        onClose={() => setShowUpdateConfirmation(false)}
        onConfirm={handleUpdate}
        message="Are you sure you want to update this ticket?"
        title="Confirm Update"
      />
      <ConfirmationModal
        show={showCompleteConfirmation}
        onClose={() => setShowCompleteConfirmation(false)}
        onConfirm={confirmComplete}
        message="Are you sure you want to mark this ticket as COMPLETE?"
        title="Confirm Completion"
      />
      <ConfirmationModal
        show={showDoneConfirmation}
        onClose={() => setShowDoneConfirmation(false)}
        onConfirm={handleDoneUpdate}
        message="Are you sure you want to mark this ticket as DONE?"
        title="Confirm DONE"
      />
      <FileUploadingModal
        isUploading={fileUploading}
      />

      {/* Modal for displaying full remark */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <textarea
          name="remark"
          className="form-control"
          rows="15"
          value={remark}
          readOnly
          style={{ width: '100%', resize: 'none' }}
        />
      </Modal>
    </div>
  );
}

export default TicketForm;

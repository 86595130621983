import { API_BASE_URL, ACCESS_TOKEN } from "../constants";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode

const request = async (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {

    headers.append("Authorization", `Bearer ${token}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  try {
    const response = await fetch(options.url, options);
    const json = await response.json();

    if (!response.ok) {
      return Promise.reject(json);
    }

    return json;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCurrentUser = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: `${API_BASE_URL}/login`,
    method: "GET",
  });
};

export const logoutUser = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/logout`, {
      method: "POST",
      credentials: "include",  // This ensures cookies are sent with the request
      headers: {
        "Authorization": `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
      }
    });

    if (!response.ok) {
      console.error(`Logout failed: ${response.statusText}`);
      return;
    }

    // Clear client-side storage after successful logout
    localStorage.clear();
    sessionStorage.clear();

    // Redirect to login page (handle the redirect manually)
    window.location.replace("/login");
  } catch (error) {
    console.error("Logout Error:", error);
    // Fallback cleanup
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login"; // Redirect to login in case of an error
  }
};


// Function to decode JWT and extract role and expiration
export const decodeJwt = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const decodedToken = jwtDecode(token);
  
  const authorities = decodedToken.authorities || [];
  const expiration = decodedToken.exp * 1000; // Convert to milliseconds
  const userEmail = decodedToken.sub;
  const userName = decodedToken.fullName;

  
  return { authorities, expiration, userEmail, userName };
};

import React, { useState, useEffect } from "react";
import { useApiClient } from "../../service/API";
import { toast } from "react-toastify";
import "../../styling/Holiday.css";

// Helper function to adjust date to Singapore Time (SGT)
const convertToSGT = (date) => {
    const sgTimeZone = "Asia/Singapore";
    const options = { timeZone: sgTimeZone };

    // Convert date to SGT by creating a new Date object and using toLocaleString for time zone conversion
    const sgtDate = new Date(date.toLocaleString('en-US', options));

    // Return the SGT date
    return sgtDate;
};

// Helper function to format Date to yyyy-MM-dd for input field
const formatDateToInputValue = (date) => {
    if (!date) return ''; // Handle null or undefined date

    // Adjust date to SGT before formatting
    const sgtDate = convertToSGT(date);

    const year = sgtDate.getFullYear();
    const month = (sgtDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2 digits
    const day = sgtDate.getDate().toString().padStart(2, '0'); // Ensure 2 digits

    return `${year}-${month}-${day}`;
};

export default function HolidayPage() {
    const [errors, setErrors] = useState({ name: '', date: '' });
    const [name, setName] = useState(null);
    const [date, setDate] = useState(null);
    const [publicHolidays, setPublicHolidays] = useState([]);
    const apiClient = useApiClient();

    const getPublicHoliday = async () => {
        try {
            const response = await apiClient.get("/holiday/get");
            setPublicHolidays(response.data);
        } catch (ex) {
            console.error(ex);
        }
    };

    useEffect(() => {
        getPublicHoliday();
    }, []);

    const validateFields = () => {
        const newErrors = {};
        let isValid = true;

        if (!date) {
            newErrors.date = 'Date is required';
            isValid = false;
        }

        if (!name) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const addHolidayHandler = async () => {
        if (!validateFields()) return;

        try {
            const response = await apiClient.post("/holiday/add", { date, name });

            if (response.status === 200) {
                setPublicHolidays(prev => [response.data, ...prev]);
                toast("Holiday added successfully!");
            }
        } catch (ex) {
            // Check if the error is a duplicate holiday error
            if (ex.response && ex.response.status === 409) {
                // Assuming the server returns a 409 Conflict when the holiday already exists
                toast.error("This holiday already exists!");
            } else {
                console.error(ex);
                toast.error("An error occurred while adding the holiday.");
            }
        }
    };

    // Function to remove holiday by ID
    const removeHoliday = async (holidayId) => {
        try {
            // Send a PATCH request to remove the holiday
            const response = await apiClient.patch(`/holiday/remove/${holidayId}`);
            if (response.status === 200) {
                // Successfully removed, update the state to reflect the change
                setPublicHolidays(prev => prev.filter(holiday => holiday.id !== holidayId));
                toast.success("Holiday removed successfully!");
            }
        } catch (error) {
            // Handle errors, like 403 for unauthorized or 404 for not found
            if (error.response && error.response.status === 403) {
                toast.error("You are not authorized to remove this holiday.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Holiday not found.");
            } else {
                toast.error("An error occurred while removing the holiday.");
            }
        }
    };

    return (
        <div className="holiday-page">
            <form>
                {/* Holiday Name Section */}
                <div className="form-group">
                    <label htmlFor="name" className="col-sm-4 col-form-label">
                        Holiday name:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={`holiday-name-input ${errors.name ? 'is-invalid' : ''}`}
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                            value={name || ''}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                </div>

                {/* Holiday Date Section */}
                <div className="form-group">
                    <label htmlFor="date" className="col-sm-4 col-form-label">
                        Holiday date:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className={`holiday-date-input ${errors.date ? 'is-invalid' : ''}`}
                            id="date"
                            onChange={(e) => { setDate(e.target.value) }}
                            value={formatDateToInputValue(date) || ''}
                        />
                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                    </div>
                </div>

                <button type="button" className="add-holiday-btn ms-2" onClick={addHolidayHandler}>
                    Add Holiday
                </button>
            </form>

            <div className="mt-4">
                <h3>Public Holidays</h3>
                <table className="public-holidays-table">
                    <thead>
                        <tr>
                            <th>Holiday Name</th>
                            <th>Holiday Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {publicHolidays.map(holiday => (
                            <tr key={holiday.id}>
                                <td>{holiday.holidayName}</td>
                                <td>{formatDateToInputValue(holiday.date)}</td>
                                <td><button className="btn btn-warning ms-3" onClick={(e)=>{
                                    e.preventDefault();
                                    removeHoliday(holiday.id);
                                }}>Remove</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip, faSpinner, faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import NotificationModal from '../NotificationModal';
import '../../../styling/ChatRoom.css';
import { useApiClient } from '../../../service/API';
import DisplayMessage from './DisplayMessage';
import data from '@emoji-mart/data'
import { init, SearchIndex } from 'emoji-mart'
import Picker from '@emoji-mart/react'
import { useWebSocketContext } from '../../WebSocket/WebSocketContext';
init({ data });

const Chat = ({ ticketId, senderEmail, receiverEmail, isChatDisabled }) => {
    const apiClient = useApiClient();
    const [messages, setMessages] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [isFileUploading, setFileUploading] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const historyPositionRef = useRef(null);
    const cursorPositionRef = useRef(null);
    const messageDisplayRef = useRef(null);
    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null);
    const emojiPickerRef = useRef(null);

    // handleNewMessage,    updateMessageReadStatus
    const {
        isConnected,
        stompClient,
        message,
        messageRead,
        alertMessage,
        mainRefresh,
        setMainRefresh,
        manageUserRefresh,
        setManageUserRefresh
    } = useWebSocketContext();

    const handleEmojiButtonClick = () => {
        setShowEmojiPicker(prev => !prev);
    };

    const handleEmojiPickup = (emoji) => {
        // Focus the text area to ensure we can insert at the correct cursor position
        textAreaRef.current.focus();

        // Get the current selection and range
        const selection = window.getSelection();
        const range = selection.getRangeAt(0); // Get the current selection range
        const cursorNode = range.startContainer; // The node where the cursor is

        const emojiText = emoji.native; // Emoji text to insert

        // If the cursor is inside a text node, we can proceed
        if (cursorNode.nodeType === Node.TEXT_NODE) {
            const textBeforeCursor = cursorNode.textContent.slice(0, historyPositionRef.current); // Text before the cursor
            const textAfterCursor = cursorNode.textContent.slice(historyPositionRef.current); // Text after the cursor

            // Insert the emoji into the text content at the cursor position
            cursorNode.textContent = textBeforeCursor + emojiText + textAfterCursor;

            // Update the selection range to place the cursor after the inserted emoji
            range.setStart(cursorNode, textBeforeCursor.length + emojiText.length);
            range.setEnd(cursorNode, textBeforeCursor.length + emojiText.length);

            // Clear previous selections and set the new range
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            // If the cursor is inside an element (not a text node)
            const emojiNode = document.createTextNode(emojiText); // Create a new text node for the emoji
            range.insertNode(emojiNode); // Insert emoji at the cursor position

            // Move the cursor after the emoji
            range.setStartAfter(emojiNode);
            range.setEndAfter(emojiNode);

            // Clear previous selections and set the new range
            selection.removeAllRanges();
            selection.addRange(range);
        }

        // Hide the emoji picker after insertion
        setShowEmojiPicker(false);
    };

    const getEmoji = async (value) => {
        const emojis = await SearchIndex.search(":"+value);
        const results = emojis.map((emoji) => emoji.skins[0].native);

        return results[0]; // Return the first emoji or empty string if no match
    }

    const colonsToUnicode = async (text) => {
        // Regex to match emojis in the format of ":)" or ":D" or ":("
        const colonsRegex = new RegExp("(:[)D(P(]+)", 'g');  // "g" flag to replace all occurrences
        let newText = text;

        // Replace all matches in the string
        let match;
        while ((match = colonsRegex.exec(text)) !== null) {
            const colons = match[0].slice(1);  // Get the character(s) after ":"
            const emoji = await getEmoji(colons);
            if (emoji) {
                newText = newText.replace(match[0], emoji); // Replace with emoji
            }
        }
        return newText;
    };

    const handleClickOutside = (event) => {
        event.preventDefault();
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
            setShowEmojiPicker(false);
        }
    };

    const isFileAlreadyUploaded = (file) => {
        if (!files || !Array.isArray(files)) {
            return false;
        }

        const existingFile = files.find(uploadedFile => uploadedFile.name === file.name);

        if (existingFile) {
            let newFileName = existingFile.name;
            while (files.some(uploadedFile => uploadedFile.name === newFileName)) {
                newFileName = getVersionedFileName(file.name);
            }
            return newFileName;
        }

        return false;
    };

    const getVersionedFileName = (fileName) => {
        const regex = /^(.*?)(\s\(\d+\))(\.[a-zA-Z0-9]+)$/;
        const match = fileName.match(regex);

        if (match) {
            const baseName = match[1];
            const versionNumber = parseInt(match[2].slice(2, -1)) + 1;
            const extension = match[3];
            return `${baseName} (${versionNumber})${extension}`;
        } else {
            return fileName;
        }
    };

    const handleNewMessage = (msg) => {
        if(msg.type !== 'REFRESH'){
            setMessages((prevMessages) => {
                const existingMessages = prevMessages.filter(message => message.msgId !== msg.msgId);
                return [...existingMessages, msg];
            });

            if (msg.createdBy !== senderEmail && msg.msgId) {
                apiClient.put(`/tickets/chat/update-read-status/${msg.msgId}`);
            }
        }
    };

    const updateMessageReadStatus = (message) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.msgId === message.msgId ? { ...msg, messageRead: message.messageRead } : msg
            )
        );
    };

    useEffect(()=>{
        if(message){
            handleNewMessage(message);
        }
    },[message]);

    useEffect(()=>{
        if(messageRead){
            updateMessageReadStatus(messageRead);
        }
    },[messageRead])

    const fetchHistoryMessageData = useCallback(async () => {
        if (!ticketId) return;

        setMessages([]);  // Clear previous messages
        setFiles([]);      // Clear previous files

        try {
            // Fetch chat history for the given ticket ID
            const response = await apiClient.get(`/tickets/chat/${ticketId}`);

            // Set the fetched messages to the state
            setMessages(response.data);

            // Filter and extract file data from the response
            const files = response.data
                .filter((data) => data.filedata && data.filedata.name) // Filter out items without filedata
                .map((data) => data.filedata); // Extract filedata

            // Set the filtered files to the state
            setFiles(files);
        } catch (error) {
            // Log the full error for debugging
            console.error('Error fetching chat history:', error);

            // Extract and display an error message if available
            const errorMessage = error.response?.data?.businessExceptionDescription || 'Error fetching chat history. Please try again.';

            // Set the error message and show the notification
            setNotificationMessage(errorMessage);
            setShowNotification(true);
        }
    }, [apiClient, ticketId]);

    useEffect(() => {
        fetchHistoryMessageData();
    }, [ticketId]);

    const sendMessage = async (content, filediscussionId = null) => {
        if((content === null || content.trim() === '') && filediscussionId === null){
            return;
        } else {
            if (isConnected) {
                const message = {
                    senderEmail,
                    receiverEmail,
                    ticketId,
                    content,
                    fileId: filediscussionId,
                };
                stompClient.send(`/app/ticket/${ticketId}`, {}, JSON.stringify(message));
                textAreaRef.current.innerHTML = null;
                scrollToBottom();
            } else {
                setNotificationMessage('Cannot send message, WebSocket is not connected.');
                setShowNotification(true);
            }
        }
    };

    const scrollToBottom = () => {
        if (messageDisplayRef.current) {
            messageDisplayRef.current.scrollTop = messageDisplayRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const uploadHandler = async (event) => {
        event.preventDefault();
        setFileUploading(true);
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length === 0) return;

        const newFiles = [];

        for (const file of selectedFiles) {
            const versionedFileName = isFileAlreadyUploaded(file);

            const allowedTypes = [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/pdf',
                'image/jpeg',
                'image/png',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'message/rfc822'
            ];

            const maxSize = 10 * 1024 * 1024;
            if (!allowedTypes.includes(file.type)) {
                setNotificationMessage('Invalid file type. Please upload valid files.');
                setShowNotification(true);
                setFileUploading(false);
                return;
            }

            if (file.size > maxSize) {
                setNotificationMessage('File size exceeds the maximum allowed size of 10 MB.');
                setShowNotification(true);
                setFileUploading(false);
                return;
            }

            if (versionedFileName) {
                const newFile = new File([file], versionedFileName, { type: file.type });
                newFiles.push(newFile);
            } else {
                newFiles.push(file);
            }

        }

        if (newFiles.length === 0) {
            setFileUploading(false);
            return;
        }

        const formData = new FormData();
        formData.append('ticketId', ticketId);
        newFiles.forEach(file => formData.append('filename', file));

        try {
            const response = await apiClient.post('/files/upload-chatfiles', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200) {
                setFiles(prevFiles => [...(prevFiles || []), response.data]);

                newFiles.forEach(file => {
                    const fileMessage = {
                        senderEmail,
                        receiverEmail,
                        ticketId,
                        content: `${file.name}`,
                        fileId: response.data.fileId,
                    };
                    sendMessage(fileMessage.content, fileMessage.fileId);
                });

                setNotificationMessage('Files uploaded successfully!');
                setShowNotification(true);
            } else {
                setNotificationMessage('Error uploading files. Please try again.');
                setShowNotification(true);
            }

            fileInputRef.current.value = null;

        } catch (error) {
            setNotificationMessage('Error uploading files. Please try again.');
            setShowNotification(true);
            fileInputRef.current.value = null;
        } finally {
            setFileUploading(false);
        }
    };

    const getSystemTimeZone = () => {
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString + 'Z');
        if (isNaN(date)) return '';

        const systemTimeZone = getSystemTimeZone();

        return date.toLocaleDateString('en-US', {
            timeZone: systemTimeZone,  // Use the system's time zone
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    const handleKeyDown = (e) => {
        if(isConnected){
            if (e.key === 'Enter' && !e.shiftKey) {
                // If Enter is pressed without Shift, send the message
                e.preventDefault();
                sendMessage(textAreaRef.current.innerHTML);
                textAreaRef.current.innerHTML = null; // Clear the message area
                scrollToBottom();
            } else if (e.key === 'Enter' && e.shiftKey) {
                // After inserting the newline, update the cursor position
                setTimeout(() => {
                    const tag = textAreaRef.current;
                    const cursor = window.getSelection();
                    const lastChild = tag.lastChild;

                    // Move cursor position to the end after inserting newline
                    const newRange = document.createRange();
                    newRange.setStart(lastChild, cursorPositionRef.current+1 || 0);
                    cursor.removeAllRanges();
                    cursor.addRange(newRange);
                }, 0.0000000001);  // Small timeout to let the DOM update before resetting the cursor position
            }
        }
    };

    const handleInputChange = async (e) => {
        const tag = textAreaRef.current;
        const selection = window.getSelection();

        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        const cursorPosition = range.startOffset;

        const content = tag.innerHTML;

        let processedContent = await colonsToUnicode(content);
        processedContent = processedContent.replace(/<\/?br\s*\/?>/g, '\n'); // Replace <br> with newlines

        tag.innerHTML = processedContent;

        // After updating the content, set the cursor back to its position
        const newRange = document.createRange();
        const lastChild = tag.lastChild;

        if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
            const newCursorPosition = Math.min(cursorPosition, lastChild.length);
            newRange.setStart(lastChild, newCursorPosition);
            newRange.setEnd(lastChild, newCursorPosition);

            selection.removeAllRanges();
            selection.addRange(newRange);
            tag.focus(); // Ensure the contentEditable is focused after update
        }
    };

    useEffect(() => {
        const handleSelectionChange = () => {
            const selection = window.getSelection();
            if (!selection.rangeCount) return;

            const range = selection.getRangeAt(0);
            const cursorOffset = range.startOffset;

            historyPositionRef.current = cursorPositionRef.current;
            cursorPositionRef.current = cursorOffset;
        };

        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, []);

    const handlePaste = (e) => {
        // You can add custom paste handling if necessary, but it should allow the default behavior
        // to work, unless you want to sanitize or modify the pasted content.
        setTimeout(() => {
            // After paste, ensure the cursor remains at the end of the content
            const tag = textAreaRef.current;
            const cursor = window.getSelection();
            const lastChild = tag.lastChild;
            const lastNodeLength = lastChild ? lastChild.length : 0;

            // Move cursor to the end of the pasted content
            const newRange = document.createRange();
            newRange.setStart(lastChild, lastNodeLength);
            newRange.setEnd(lastChild, lastNodeLength);
            cursor.removeAllRanges();
            cursor.addRange(newRange);
        }, 0); // Allow paste operation to complete before resetting the cursor
    };

    return (
        <div className="chat-room">
            <h2>Chat Room for Ticket ID: {ticketId}</h2>
            <div className="message-display" ref={messageDisplayRef}>
                {messages && messages.length === 0 ? (
                    <p>No messages yet.</p>
                ) : (
                    messages.map((msg) => (
                        <div
                            className={`message ${msg.createdBy === senderEmail ? 'sender' : 'receiver'}`}
                            key={msg.msgId}
                        >
                            <DisplayMessage msg={msg} formatDate={formatDate} apiClient={apiClient} />
                        </div>
                    ))
                )}
            </div>

            <div className="input-area">
                <FontAwesomeIcon icon={faPaperclip} onClick={() => fileInputRef.current.click()} />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={uploadHandler}
                />
                <div className="form-control chat-input-area">
                    <div
                        spellCheck="true"
                        className="message-input area"
                        contentEditable={!(isFileUploading || isChatDisabled) && isConnected}
                        ref={textAreaRef}
                        onInput={handleInputChange}
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown}
                    />
                    <FontAwesomeIcon className='icon' icon={faFaceSmile} onClick={() =>  isConnected? handleEmojiButtonClick(): undefined} ref={emojiPickerRef}/>
                </div>
                {showEmojiPicker && (
                <div className='picker'>
                    <Picker
                    data={data}
                    categories={[
                        'people',
                        'activity',
                        // 'flags',
                        // 'foods',
                        // 'frequent',
                        'nature',
                        // 'objects',
                        // 'places',
                        // 'symbols',
                    ]}
                    onEmojiSelect={handleEmojiPickup}
                    onClick={() => textAreaRef.current?.focus()}
                    onClickOutside={handleClickOutside}
                    theme="light"
                    emojiSize={16}
                    previewPosition="none"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        width: 'auto',
                        maxWidth: '400px',
                    }}
                    />
                </div>
                )}
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => sendMessage(textAreaRef.current.innerHTML)}
                    disabled={(!ticketId || isFileUploading || isChatDisabled) && isConnected}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {isFileUploading && isConnected ? (
                        <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '5px' }} />
                    ) : (
                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} />
                    )}
                    Send
                </button>
            </div>

            <NotificationModal
                show={showNotification}
                message={notificationMessage}
                onClose={() => setShowNotification(false)}
            />
        </div>
    );
};

export default Chat;

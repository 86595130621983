import React, { useReducer, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../../service/API';
import useTickets from '../../hooks/useTickets';
import FilterForm from './FilterForm';
import TicketTable from './TicketTable';
import PaginationControls from './PaginationControls';
import NotificationModal from '../TicketComponents/NotificationModal';
import { useAuth } from '../../auth/AuthHandler';
import { useWebSocketContext } from '../WebSocket/WebSocketContext';
import '../../styling/TicketList.css';

// Reducer for managing state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_SIZE':
      return { ...state, pageSize: action.payload, currentPage: 0 };
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 0 };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload };
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: { ticketId: '', createdBy: '', assignTo: '', status: '', sysFreightRef: '', stage: '', sortBy: 'ticketId', sortDirection: 'ASC', startDate: '', endDate: ''},
        pageSize: 10,
        currentPage: 0
      };
    default:
      return state;
  }
};

const TicketList = ({title}) => {
  const apiClient = useApiClient();
  const { expiration, authenticated, userEmail, userName, userRoles } = useAuth();
  const [executeFilter, setExecuteFilter] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    filters: { ticketId: '', createdBy: '', assignTo: '', status: '', sysFreightRef: '', stage: '', sortBy: 'ticketId', sortDirection: 'ASC', startDate: '', endDate: '' },
    pageSize: 10,
    currentPage: 0
  });

  const { isConnected, stompClient, message, messageRead, alertMessage, mainRefresh, setMainRefresh, manageUserRefresh } = useWebSocketContext();

  const { tickets, loading, totalPages, error } = useTickets(state.filters, state.pageSize, state.currentPage, setMainRefresh, executeFilter, setExecuteFilter, title);

  const navigate = useNavigate();

  const [notificationMessage, setNotificationMessage] = useState(''); // Notification message

  // New state for filter inputs (temporary)
  const [tempFilters, setTempFilters] = useState(state.filters);

  useEffect(() => {
    const fetchData = async () => {
      const savedState = sessionStorage.getItem('ticketListState');
      if (savedState) {
        const parsedState = await JSON.parse(savedState);
        dispatch({ type: 'SET_FILTERS', payload: parsedState.filters });
        dispatch({ type: 'SET_PAGE_SIZE', payload: parsedState.pageSize });
        dispatch({ type: 'SET_CURRENT_PAGE', payload: parsedState.currentPage });
        setTempFilters(parsedState.filters);
        setExecuteFilter(true);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
          setExecuteFilter(true);
      }
    };

    fetchData();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(()=>{
    if(isConnected){
      setExecuteFilter(true);
    }
  },[isConnected])

  useEffect(()=>{
    if(title==='leave'){
      setExecuteFilter(true);
    }
  },[manageUserRefresh])

  useEffect(()=>{
    setExecuteFilter(true);
  },[mainRefresh]);

  const handleFilterChange = (event) => {
    let fieldName, fieldValue;

    if (event && event.target && event.target.name) {
      event.preventDefault();
      const { name, value } = event.target;
      fieldName = name;
      fieldValue = value;
    } else {
      const { name, value } = event;
      fieldName = name;
      fieldValue = value;
    }

    // Update tempFilters for the form inputs
    setTempFilters({ ...tempFilters, [fieldName]: fieldValue });
  };

  const updateSessionStorage = (filters, pageSize, currentPage) => {
    const stateToStore = { filters, pageSize, currentPage };
    sessionStorage.setItem('ticketListState', JSON.stringify(stateToStore));
  };

  const handlePageSizeChange = (size) => {
    dispatch({ type: 'SET_PAGE_SIZE', payload: size });
    updateSessionStorage(state.filters, size, state.currentPage);
  };

  const handleResetFilters = (e) => {
    e.preventDefault();
    // Reset the filter form's temporary state
    setTempFilters({
      ticketId: '',
      createdBy: '',
      assignTo: '',
      status: '',
      sysFreightRef: '',
      stage: '',
      startDate: '',
      endDate: '',
      sortBy: 'ticketId',
      sortDirection: 'ASC'
    });
    dispatch({ type: 'RESET_FILTERS' });
    updateSessionStorage({
      ticketId: '',
      createdBy: '',
      assignTo: '',
      status: '',
      sysFreightRef: '',
      stage: '',
      startDate: '',
      endDate: '',
      sortBy: 'ticketId',
      sortDirection: 'ASC'
    }, 10, 0);
    setExecuteFilter(true);
  };

  const applyFilter = (e) => {
    e.preventDefault();
    // Apply filters by updating the global state
    dispatch({ type: 'SET_FILTERS', payload: tempFilters });
    updateSessionStorage(tempFilters, state.pageSize, state.currentPage);
    setExecuteFilter(true);
  };

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      dispatch({ type: 'SET_CURRENT_PAGE', payload: page });
      updateSessionStorage(state.filters, state.pageSize, page);
      setExecuteFilter(true);
    }
  };

  const handleViewButton = (ticketId) => {
    navigate(`/view-ticket/${ticketId}`);
  };

  const handleCERButton = async (status, ticketId) => {
    try {
      const response = await apiClient.patch(`/tickets/${status}/${ticketId}`);
      if(['REWORK','ERROR'].includes(status) ){
        const dataId = response.data.id;
        navigate(`/update-ticket/${dataId}`);
      } else {
        setExecuteFilter(true);
        setNotificationMessage(`Ticket ${ticketId} updated successful to ${status}!`);
      }
    } catch (error) {
      console.error('Error during approval:', error);
    }
  };

  const handleApprovalButton = async (ticketId, status) => {
    try {
      await apiClient.patch(`/tickets/urgent-approval/${ticketId}/${status}`);
      setExecuteFilter(true);
    } catch (error) {
      console.error('Error during approval:', error);
    }
  };

  useEffect(() => {
    if (error) {
      setNotificationMessage(error);
    }
  }, [error]);

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      setTempFilters({ ...tempFilters, createdBy: userEmail });
      updateSessionStorage({ ...tempFilters, createdBy: userEmail }, state.pageSize, state.currentPage);
      setExecuteFilter(true);
    } else {
      setTempFilters({ ...tempFilters, createdBy: 'All' });
      updateSessionStorage({ ...tempFilters, createdBy: 'All' }, state.pageSize, state.currentPage);
      setExecuteFilter(true);
    }
  }, [isChecked]);

  const onReverseSort = (name) => {
    const newSortDirection = state.filters.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    dispatch({ type: 'SET_FILTERS', payload: { ...state.filters, sortBy: name, sortDirection: newSortDirection } });
    setExecuteFilter(true);
  };

  return (
    <div className='ticket-list'>
      {userRoles.includes("FOUSER") &&
        <div className="switch-container">
        <input
          type="checkbox"
          id="toggle"
          className="switch-checkbox"
          checked={isChecked}
          onChange={handleToggleChange}
        />
        <label className="switch-label" htmlFor="toggle">
          <span className="switch-option">All</span>
          <span className="switch-option">My Tickets</span>
        </label>
      </div>}

      <FilterForm
        notShowCreatedByFilter={!isChecked}
        filters={tempFilters} // Pass tempFilters for the form inputs
        pageSize={state.pageSize}
        onFilterChange={handleFilterChange}
        onPageSizeChange={handlePageSizeChange}
        onResetFilters={handleResetFilters}
        onApplyFilters={applyFilter}
      />

      <TicketTable
        loading={loading}
        tickets={tickets}
        filters={state.filters}
        onViewButton={handleViewButton}
        onApprovalButton={handleApprovalButton}
        onCERButton={handleCERButton}
        onReverseSort={onReverseSort}
      />
      <PaginationControls
        currentPage={state.currentPage}
        totalPages={totalPages}
        onPreviousPage={() => handlePageChange(state.currentPage - 1)}
        onNextPage={() => handlePageChange(state.currentPage + 1)}
      />
      {/* Notification Modal */}
      <NotificationModal
        show={Boolean(notificationMessage)}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
      />
    </div>
  );
};

export default TicketList;

import React, { useState } from 'react';
import FileItem from './FileItem';
import '../../styling/FileList.css';
import {useApiClient} from '../../service/API';
import NotificationModal from '../TicketComponents/NotificationModal';


function FileList({ files = [], removeFile, isUploading, folderName }) {
    const apiClient = useApiClient();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const deleteFileHandler = (files) => {
        apiClient.put(`/files/remove/${files.fileId}`)
        .then((response) => {
            if (response.status === 200) {
                // Call removeFile function if the response status is 200
                removeFile(files.name);
            } else {
                // Handle the case where the status is not 200
                setErrorMessage(`Failed to remove file: ${response.status}. Please contact IT support!`);
                setShowModal(true);
            }
        })
        .catch((error) => {
            // Handle network errors or other issues
            setErrorMessage(`Error removing file: ${error.response ? error.response.data : error.message}. Please contact IT support!`);
            setShowModal(true);
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage(''); // Clear the error message
    };

    return (
        <div className="file-list">
            {files && files.map(file => (
                <FileItem
                    key={file.name}
                    file={file}
                    deleteFile={() => deleteFileHandler(file)} // Pass file name to delete handler
                    isUploading={isUploading}
                />
            ))}
            <NotificationModal
                show={showModal}
                message={errorMessage}
                onClose={handleCloseModal}
            />
        </div>
    );
}

export default FileList;
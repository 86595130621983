import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GOOGLE_AUTH_URL } from "../../constants";
import "../../styling/Error.css";

const ErrorPage = () => {
    const location = useLocation();
    const query = new URLSearchParams(useLocation().search);
    const error = query.get('error') || "An unexpected error occurred.";
    const message = query.get('message') || "An unexpected error occurred.";

    useEffect(() => {
          const timer = setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace(GOOGLE_AUTH_URL);
          }, 3000);

          // Directly navigate if the error message indicates a cancel
          if (error.includes("access_denied")) {
              clearTimeout(timer);
              window.location.replace(GOOGLE_AUTH_URL);
          }

          return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [location]);

    return (
        <div className="error-page">
            <h1>Error - {error}</h1>
            <p>{message}</p>
            <p>You will be redirected shortly...</p>
        </div>
    );
};

export default ErrorPage;

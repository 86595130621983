import React from "react";

export default function LeaveList ({leavelist = [], loading, onApprovalButton, onCancelButton, title}){
    return (
        <div
            style={{
                maxHeight: '400px', // Adjust this value as needed
                overflowY: 'auto',
                display: 'block',
                width: '100%',
                height: 'calc(100vh - 100px)' // Adjust based on your layout
            }}
        >
            <table className="table table-bordered">
                <thead className="table-secondary"
                style={{
                    position: 'sticky',
                    top: 0, // This makes the header stay at the top
                    backgroundColor: 'black !important', // Make sure it matches your table header background
                    zIndex: 1, // Ensure it stays above the table rows
                }}
                >
                    <tr>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Name</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Email</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Status</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Request On</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Reason</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Start Date</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >End Date</th>
                    {title==='request'?
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Action</th>
                    :
                    <>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Approve By</th>
                    <th scope="col"  style={{ width: '120px' }} className="normal-col" >Approve Date</th>
                    </>
                    }
                    {title==='own' && <th scope="col"  style={{ width: '120px' }} className="normal-col" >Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {(leavelist.length === 0 || loading) ? (
                    <tr>
                        <td colSpan="9" className="text-center">No leave request</td>
                    </tr>
                    ) : (
                        leavelist.map((leave) => (
                        <tr key={leave.id}>
                        <td>{leave.fullname}</td>
                        <td>{leave.email}</td>
                        <td className={leave.status}>{leave.status}</td>
                        <td>{new Date(leave.createdDate + 'Z').toLocaleDateString()}</td>
                        <td>{leave.reason}</td>
                        <td>{leave.startDate}</td>
                        <td>{leave.endDate}</td>
                        {title==='request'?
                        <td>
                            <div className="d-flex">
                                <button className="btn btn-success ms-3" onClick={() => onApprovalButton(leave.id, "APPROVED")}>APPROVE</button>
                                <button className="btn btn-danger ms-3" onClick={() => onApprovalButton(leave.id, "REJECTED")}>REJECT</button>
                            </div>
                        </td>
                        :
                        <>
                            <td>{leave.approvedBy}</td>
                            <td>{new Date(leave.approvedDate + 'Z').toLocaleDateString()}</td>
                        </>
                        }
                        {title==='own' && leave.status === 'PENDING' && <td><button className="btn btn-danger ms-3" onClick={() => onCancelButton(leave.id)}>CANCEL</button></td>}
                        </tr>
                    ))
                    )}
                </tbody>
                </table>
        </div>
    );
}
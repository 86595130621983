import React, { useState } from 'react';
import { useAuth } from '../../auth/AuthHandler';
import ConfirmationModal from '../TicketComponents/ConfirmationModal';

function TicketTable({ loading, tickets = [], filters, onViewButton, onApprovalButton, onCERButton, onReverseSort }) {
  const { userRoles } = useAuth();
  const [showModal, setShowModal] = useState(false);  // State to control modal visibility
  const [modalMessage, setModalMessage] = useState('');  // State to store the modal message
  const [modalTitle, setModalTitle] = useState('');  // State to store the modal title
  const [ticketIdToUpdate, setTicketIdToUpdate] = useState(null);  // Store the ticket ID to be updated
  const [actionType, setActionType] = useState('');  // Store the action type (e.g., DONE, ERROR, etc.)

  // Helper function to show the confirmation modal
  const handleActionWithConfirmation = (actionType, ticketId) => {
    let message = '';
    let title = '';

    // Set the confirmation modal message based on action type
    if (actionType === 'DONE') {
      title = 'Confirm Done';
      message = 'Are you sure you want to mark this ticket as DONE?';
    } else if (actionType === 'ERROR') {
      title = 'Confirm Error';
      message = 'Are you sure you want to mark this ticket as ERROR?';
    } else if (actionType === 'REWORK') {
      title = 'Confirm Rework';
      message = 'Are you sure you want to mark this ticket as REWORK?';
    } else if (actionType === 'CANCELLED') {
      title = 'Confirm Cancelled';
      message = 'Are you sure you want to cancel this ticket?';
    }

    // Show the modal
    setModalMessage(message);
    setModalTitle(title);
    setTicketIdToUpdate(ticketId);
    setActionType(actionType);
    setShowModal(true);
  };

  const handleConfirmAction = () => {
    // Call the onCERButton function based on the action
    onCERButton(actionType, ticketIdToUpdate);

    // Close the modal after confirming
    setShowModal(false);
  };

  const handleCloseModal = () => {
    // Close the modal without taking action
    setShowModal(false);
  };

  // Helper function to apply class for rotation based on sort direction
  const getSortIconClass = (column) => {
    return filters.sortBy === column ? (filters.sortDirection === 'ASC' ? '' : 'rotated') : '';
  };

  const getSystemTimeZone = () => {
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString + 'Z');
    if (isNaN(date)) return '';

    const systemTimeZone = getSystemTimeZone();

    return date.toLocaleDateString('en-GB', {
        timeZone: systemTimeZone,  // Use the system's time zone
        day: '2-digit',
        month: 'short',  // Abbreviated month (e.g., Jan, Feb, Mar)
        year: 'numeric',
    }) + ' ' + date.toLocaleTimeString('en-GB', {
        timeZone: systemTimeZone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,  // 24-hour format
    });
  };

  return (
    <>
      {/* Confirmation Modal */}
      <ConfirmationModal
          show={showModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirmAction}
          message={modalMessage}
          title={modalTitle}
      />
      <table className="table table-bordered">
        <thead className="table-secondary">
          <tr>
            {/* Ticket ID Column */}
            <th scope="col" style={{ width: '130px' }} className="sortable" onClick={() => onReverseSort('ticketId')}>
              Ticket Id
              {filters.sortBy === 'ticketId' &&
              <div
                className={`sort-icon ${getSortIconClass('ticketId')}`}
                role="button"
                tabIndex="0"
                aria-label="Reverse sort direction"
              >
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 48 48"
                  focusable="false"
                  fill="currentColor"
                  className="sort-svg"
                >
                  <path fill="none" d="M0 0h48v48H0V0z"></path>
                  <path d="M8 24l2.83 2.83L22 15.66V40h4V15.66l11.17 11.17L40 24 24 8 8 24z"></path>
                </svg>
              </div>}
            </th>

            {/* SysFreight No. Column */}
            <th scope="col" style={{ width: '170px' }} className="sortable" onClick={() => onReverseSort('sysFreightRef')}>
              SysFreight No.
              {filters.sortBy === 'sysFreightRef' &&
              <div
                className={`sort-icon ${getSortIconClass('sysFreightRef')}`}
                role="button"
                tabIndex="0"
                aria-label="Reverse sort direction"
              >
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 48 48"
                  focusable="false"
                  fill="currentColor"
                  className="sort-svg"
                >
                  <path fill="none" d="M0 0h48v48H0V0z"></path>
                  <path d="M8 24l2.83 2.83L22 15.66V40h4V15.66l11.17 11.17L40 24 24 8 8 24z"></path>
                </svg>
              </div>
              }
            </th>

            {/* Other columns */}
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Created By</th>
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Assign To</th>
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Stage</th>
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Status</th>
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Created On</th>
            <th scope="col"  style={{ width: '120px' }} className="normal-col" >Action</th>
          </tr>
        </thead>
        <tbody>
          {(tickets.length === 0 || loading) ? (
            <tr>
              <td colSpan="8" style={{height: '500px'}} className="text-center">No tickets available</td>
            </tr>
          ) : (
            tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>{ticket.id}</td>
                <td>{ticket.sysFreightRef}</td>
                <td>{ticket.createdBy}</td>
                <td>{ticket.assignedTo}</td>
                <td>{ticket.stage}</td>
                <td className={ticket.status}>{ticket.status}</td>
                <td>{formatDate(ticket.createdDate)}</td>
                <td>
                  <div className="d-flex">
                    {(['FOMANAGER', 'BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && ticket.status === "PENDING") &&
                    (
                      <>
                        <button className="btn btn-success ms-3" onClick={() => onApprovalButton(ticket.id, "APPROVED")}>APPROVE</button>
                        <button className="btn btn-danger ms-3" onClick={() => onApprovalButton(ticket.id, "REJECTED")}>REJECT</button>
                      </>
                    )}
                    <a href={`/view-ticket/${ticket.id}`} >
                      <button className="btn btn-warning ms-3">
                        View
                      </button>
                    </a>
                    {((ticket.status === 'COMPLETED') && ['FOUSER', 'FOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))) && (
                      <button type="button" className="btn btn-primary ms-3" onClick={()=>handleActionWithConfirmation('DONE',ticket.id)}>
                        DONE
                      </button>
                    )}
                    {(['FOUSER', 'FOMANAGER', 'BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && !["PENDING","CANCELLED","REWORK","ERROR"].includes(ticket.status) ) &&
                    (
                      <>
                        <button className="btn btn-danger ms-3" onClick={() => handleActionWithConfirmation("CANCELLED",ticket.id)}>Cancel</button>
                        {ticket.status === "COMPLETED" &&<div className="btn-group ms-3" role="group">
                          <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            Error/Rework
                          </button>
                          <ul className="dropdown-menu">
                            <li><a className="dropdown-item" onClick={() => handleActionWithConfirmation("ERROR",ticket.id)}>Error</a></li>
                            <li><a className="dropdown-item" onClick={() => handleActionWithConfirmation("REWORK",ticket.id)}>Rework</a></li>
                          </ul>
                        </div>}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
}

export default TicketTable;
import React from "react";
import '../../styling/TicketStatus.css'; // Import the CSS file

export default function TicketStatusLog({ticketstatuslog}){

    const getSystemTimeZone = () => {
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone;
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString + 'Z');
        if (isNaN(date)) return '';

        const systemTimeZone = getSystemTimeZone();

        return date.toLocaleDateString('en-GB', {
            timeZone: systemTimeZone,  // Use the system's time zone
            day: '2-digit',
            month: 'short',  // Abbreviated month (e.g., Jan, Feb, Mar)
            year: 'numeric',
        }) + ' ' + date.toLocaleTimeString('en-GB', {
            timeZone: systemTimeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,  // 24-hour format
        });
      };

    return(
        <div className='ticket-status-log'>
            <table>
                <thead>
                    <tr>
                        <th scope="col"  style={{ width: '120px' }} className="normal-col" >Status</th>
                        <th scope="col"  style={{ width: '120px' }} className="normal-col" >Created On</th>
                    </tr>
                </thead>
                <tbody>
                    {ticketstatuslog?
                    ticketstatuslog.map((status) => (
                    <tr key={status.id}>
                        <td>{status.status}</td>
                        <td>{formatDate(status.createdOn)}</td>
                    </tr>))
                    :
                    <tr>
                        <td colSpan="8" style={{height: '500px'}} className="text-center">No status available</td>
                    </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCurrentUser, logoutUser, decodeJwt } from '../util/APIUtils.js';
import { ACCESS_TOKEN } from '../constants';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [expiration, setExpiration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [mute, setMute] = useState(false);
  const token = localStorage.getItem(ACCESS_TOKEN);
  let timer; // Declare timer variable

  const loadCurrentlyLoggedInUser = async () => {
    setLoading(true);
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const response = await getCurrentUser(); // Fetch user data if token exists
      setMute(response.mute.mute);
      const { authorities, expiration, userEmail, userName } = decodeJwt();
      handleLogin(authorities, expiration, userEmail, userName);
    } catch (error) {
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (authorities, expiration, userEmail, userName) => {
    setUserRoles(authorities);
    setExpiration(expiration);
    setUserEmail(userEmail);
    setUserName(userName);
    setAuthenticated(true);
    startSessionTimer(); // Start timer on login
  };

const handleLogout = async () => {
    try {
        setAuthenticated(false);
        await logoutUser();
        setUserRoles([]);
        setExpiration(0);
        setUserEmail(null);
        setUserName(null);
        clearTimeout(timer); // Clear timer on logout
        toast.success("You're safely logged out!");
    } catch (error) {
        toast.error("Logout failed. Please try again.");
    }
};

  const startSessionTimer = () => {
    // Calculate the time remaining until expiration
    const timeRemaining = expiration - Date.now();
    if (timeRemaining > 0) {
      timer = setTimeout(() => {
        handleLogout(); // Logout when timer expires
      }, timeRemaining);
    }
  };

  useEffect(() => {
    if (!token) {
      setLoading(false);
    }else{
      loadCurrentlyLoggedInUser();
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ expiration, authenticated, userEmail, userName, userRoles, loading, handleLogout, handleLogin, mute, setMute }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

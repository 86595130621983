import React, { useEffect } from "react";
import { GOOGLE_AUTH_URL, ACCESS_TOKEN } from "../../constants";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'; // Import toast from react-toastify
import { logoutUser } from "../../util/APIUtils";

const Login = ({ authenticated, location }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const error = searchParams.get('error');
        const message = searchParams.get('message');

        // Handle errors first
        if (error) {
            toast.error(message || 'Authentication failed', {
                autoClose: false,
                closeOnClick: false,
                toastId: 'auth-error'
            });
            window.location.replace(GOOGLE_AUTH_URL);
        }

        // Handle OAuth2 errors
        if (location.state && location.state.error) {
          toast.error(location.state.error, { autoClose: 5000 }); // Use toast for errors
        }
    }, [location]);

    // Redirect to Google Auth URL if not authenticated
    if (!authenticated) {
        logoutUser();
        window.location.replace(GOOGLE_AUTH_URL); // Try using replace
        return null; // Prevent rendering the component
    }

    if(!localStorage.getItem(ACCESS_TOKEN)){
        window.location.replace(GOOGLE_AUTH_URL);
    }

    // Redirect to the home page if authenticated
    return navigate('/home', { replace: true });
};

export default Login;

import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useApiClient } from '../../service/API';
import '../../styling/FileUpload.css';
import NotificationModal from '../TicketComponents/NotificationModal'; // Adjust the import path as needed
const FileUpload = ({ setFileUploading, files, setFiles, ticketFolderName, setTicketFolderName }) => {
  const [error, setError] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const fileInputRef = useRef(null);
  const apiClient = useApiClient();

  const isFileAlreadyUploaded = (file) => {
    return files.some(existingFile => existingFile.name === file.name);
  };

  const uploadHandler = async (event) => {
    event.preventDefault(); // Prevent form submission
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length === 0) return;

    // Clear previous error
    setError('');

    const newFiles = [];

    for (const file of selectedFiles) {
      if (isFileAlreadyUploaded(file)) {
        setError('Same files have already been uploaded.');
        continue;
      }

      // Define allowed file types and max size
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/pdf', // .pdf
        'image/jpeg', // .jpeg
        'image/png', // .png
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'message/rfc822' // .eml
      ];

      if (!allowedTypes.includes(file.type)) {
        setError('Invalid file type. Please upload valid files.');
        return;
      }

      // Validate file size (e.g., 10 MB)
      const maxSize = 10 * 1024 * 1024; // 10 MB
      if (file.size > maxSize) {
        setError('File size exceeds the maximum allowed size of 10 MB.');
        return;
      }

      newFiles.push(file);
    }

    if (newFiles.length === 0) return;

    const formData = new FormData();
    formData.append('folderName', ticketFolderName);
    newFiles.forEach(file => formData.append('filename', file));

    try {
      setFileUploading(true);
      const response = await apiClient.post('/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setNotificationMessage('Files uploaded successfully!');
        setTicketFolderName(response.data.folderName);
        setFiles(prevFiles => [...prevFiles, ...response.data.fileResponse]);
        setShowNotification(true); // Show the notification modal
      }

      // Reset the file input value
      fileInputRef.current.value = null;

    } catch (error) {
      // console.error('Error uploading files:', error.response ? error.response.data : error.message);
      setNotificationMessage('Error uploading files. Please try again.');
      setShowNotification(true); // Show the notification modal
      // Reset the file input value
      fileInputRef.current.value = null;
    } finally {
      setFileUploading(false);
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleButtonClick = (event) => {
    event.preventDefault(); // Prevent form submission or any other default action
    fileInputRef.current.click(); // Trigger the file input click manually
  };

  return (
    <div className="file-card-upload">
      <div className="file-inputs">
        <input
          type="file"
          ref={fileInputRef}
          onChange={uploadHandler}
          accept=".docx, .pdf, .jpg, .jpeg, .png, .xls, .xlsx, .eml"
          multiple // Allow multiple file selection
        />
        <button onClick={handleButtonClick} className="upload-button">
          <FontAwesomeIcon icon={faPlus} className="icon" /> Upload
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      <p className="main">Supported files</p>
      <p className="info">.docx, .pdf, .jpg, .jpeg, .png, .xls, .xlsx, or .eml <br />
      (Each file should not exceed 10 MB)
      </p>

      {/* Notification Modal */}
      <NotificationModal
        show={showNotification}
        message={notificationMessage}
        onClose={handleCloseNotification}
      />
    </div>
  );
};

export default FileUpload;

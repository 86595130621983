import React, { useState, useEffect } from "react";
import CalendarPicker from "./Calender";
import LeaveRequestForm from "./LeaveRequestForm";
import LeaveList from "./LeaveList";
import { useApiClient } from "../../service/API";
import { useAuth } from "../../auth/AuthHandler";
import { toast } from 'react-toastify';
import "../../styling/Leave.css"

export default function LeaveRequestPage ({}){
    const { expiration, authenticated, userEmail, userName, userRoles, handleLogout, handleLogin } = useAuth();
    const [leaveRequestInfo, setLeaveRequestInfo] = useState({email: null, startDate: null, endDate: null, duration: null, reason: null, category: null})
    const [leaveAvailable, setLeaveAvailable] = useState({});
    const [leaveList, setLeaveList] = useState([]);
    const [publicHolidays, setPublicHolidays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const apiClient = useApiClient();
    console.log(leaveRequestInfo);

    const getPublicHoliday = async () => {
        try{
            setLoading(true);
            const response = await apiClient.get("/holiday/get");
            setPublicHolidays(response.data);
        }catch(ex){
            console.error(ex);
        }finally{
            setLoading(false);
        }
    }

    const setInfo = (name, value) => {
        setLeaveRequestInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getLeaveAvailable = async () => {
        try{
            setLoading(true);
            const response = await apiClient.get("/users/leave-available");
            if(response.status === 200){
                setLeaveAvailable(response.data);
            }
        }catch(ex){
            console.error(ex);
        }finally{
            setLoading(false);
        }
    }

    const getOwnLeaveList = async () => {
        try{
            setLoading(true);
            const response = await apiClient.get("/users/own-leave-request-list");
            if(response.status === 200){
                setLeaveList(response.data);
            }
        }catch(ex){
            console.error(ex);
        }finally{
            setLoading(false);
        }
    }

    const fetchUserList = async () => {
        try {
          const userListGet = await apiClient.get(`/users/bouser-list`);
          setUserList(userListGet.data);
        } catch (error) {
          // handleApiError(error, 'Error fetching user list. Please contact IT support.');
        }
      };

    // The function to trigger the leave request after confirmation
    const handleRequestLeave = async (info) => {
        if(['BOUSER'].some(role => userRoles.includes(role))){
            const response = await apiClient.post("/users/request-leave",
                info,
                { headers: { 'Content-Type': 'application/json' }});
            if(response.status === 200){
                toast(response.data);
                getLeaveAvailable();
                getOwnLeaveList();
            }
        }else{
            const response = await apiClient.post("/users/update-user-to-leave",
                info,
                { headers: { 'Content-Type': 'application/json' }});
            if(response.status === 200){
                toast(response.data);
                getLeaveAvailable();
                getOwnLeaveList();
            }
        }

    };

    useEffect(()=>{
        if(!['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role))){
            setInfo("email",userEmail);
        }else{
            fetchUserList();
        }
        getPublicHoliday();
        getLeaveAvailable();
        getOwnLeaveList();
    },[])

    // Check if a date is a public holiday
    const isPublicHoliday = (year, month, day) => {
        if (!day) return false; // Check if day is valid (not null or undefined)

        // Format the date to yyyy-MM-dd
        const inputDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        // Check if any public holiday's date matches the input date
        return publicHolidays.some(holiday => {
            const holidayDate = holiday.date; // e.g., '2025-03-01'
            return holidayDate === inputDate; // Compare the formatted dates
        });
    };


    const calculateDurationExcludingWeekendsAndHolidays = (startDate, endDate) => {
        if (!startDate || !endDate) {
            return 0; // Return 0 if either date is missing
        }

        // Initialize the count of days excluding weekends and public holidays
        let totalDaysExcludingWeekendsAndHolidays = 0;

        // Loop through each day in the date range
        for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
            const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const day = currentDate.getDate();

            // Only count weekdays (Monday to Friday) and exclude public holidays
            const isHoliday = isPublicHoliday(year, month, day);
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday) {
                totalDaysExcludingWeekendsAndHolidays++;
            }
        }

        return totalDaysExcludingWeekendsAndHolidays;
    };

    useEffect(()=>{
        setInfo("duration",calculateDurationExcludingWeekendsAndHolidays(leaveRequestInfo.startDate, leaveRequestInfo.endDate));
    },[leaveRequestInfo.startDate,leaveRequestInfo.endDate])

    const onCancelButton = async (leaveId) => {
        console.log(leaveId);
        try{
            await apiClient.patch(`/users/cancel-leave/${leaveId}`);
        } catch (err) {
            console.error(err);
        }
    }

    if(loading){
        return <div>Loading...</div>
    }

    return (
        <div className="leave-request-page">
            <div className="leave-status">
                <h6>Annual Leave available: {leaveAvailable.annual}</h6>
                <h6>Medical Leave available: {leaveAvailable.medical}</h6>
                <h6>Hospital Leave available: {leaveAvailable.hospital}</h6>
            </div>
            <div className="leave-calender">
                <CalendarPicker startDate={leaveRequestInfo.startDate} endDate={leaveRequestInfo.endDate} setInfo={setInfo} isPublicHoliday={isPublicHoliday} />
                <LeaveRequestForm info={leaveRequestInfo} setInfo={setInfo} handleRequestLeave={handleRequestLeave} userList={userList}/>
            </div>
            {['BOUSER'].some(role => userRoles.includes(role)) &&
            <div className="own-leave-list" >
                <LeaveList leavelist={leaveList} loading={false} onCancelButton={onCancelButton} title="own" />
            </div>}
        </div>
    );
}
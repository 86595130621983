import React, { useState } from 'react';

const CalendarPicker = ({startDate = null, endDate = null, setInfo, isPublicHoliday}) => {
    const monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const [currentDate, setCurrentDate] = useState(new Date());

    // Helper function to get the number of days in a month
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    // Get the first day of the month (0 is Sunday, 6 is Saturday)
    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1).getDay();
    };

    // Get the previous month's last few days to fill the first week
    const getPreviousMonthDays = (year, month) => {
        const firstDay = getFirstDayOfMonth(year, month); // Which day of the week the 1st falls on
        const prevMonth = month === 0 ? 11 : month - 1;
        const prevMonthYear = month === 0 ? year - 1 : year;
        const daysInPrevMonth = getDaysInMonth(prevMonthYear, prevMonth);

        const prevMonthDays = [];
        for (let i = daysInPrevMonth - firstDay + 1; i <= daysInPrevMonth; i++) {
            prevMonthDays.push(i);
        }
        return prevMonthDays;
    };

    // Get the next month's first few days to fill the last week
    const getNextMonthDays = (year, month) => {
        const lastDayOfMonth = getDaysInMonth(year, month);
        const lastDay = new Date(year, month, lastDayOfMonth).getDay();
        const nextMonthDays = [];

        // Get enough days from the next month to fill the last row
        for (let i = 1; nextMonthDays.length < (7 - (lastDay + 1)); i++) {
            nextMonthDays.push(i);
        }

        return nextMonthDays;
    };

    const getCalendarGrid = (year, month) => {
        const daysInMonth = getDaysInMonth(year, month);
        const firstDay = getFirstDayOfMonth(year, month);
        const days = [];

        // Get today's date for comparison
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        const todayDay = today.getDate();

        // Fill in days from the previous month if needed
        const prevMonthDays = getPreviousMonthDays(year, month);
        const nextMonthDays = getNextMonthDays(year, month);

        // Add previous month's days to the grid
        for (let i = 0; i < firstDay; i++) {
            const isOutOfRange = (todayYear > year) || (year === todayYear && todayMonth > month + 90 - 1) || (year === todayYear &&  month + 90 - 1 === todayMonth && todayDay > i);

            days.push({
                "day": prevMonthDays[i],
                "month": month === 0 ? 11 : month - 1,
                "isOutOfRange": isOutOfRange // Past days of previous month are "out of range"
            });
        }

        // Add the actual days of the current month
        for (let i = 1; i <= daysInMonth; i++) {
            const isOutOfRange = (todayYear > year) || (year === todayYear && todayMonth > month + 90) || (year === todayYear && month + 90 === todayMonth && todayDay > i);

            days.push({
                "day": i,
                "month": month,
                "isOutOfRange": isOutOfRange // Set to true if the day is in the future
            });
        }

        // Add next month's days to the grid if needed
        while (days.length < 42) {
            const isOutOfRange = (todayYear > year) || (year === todayYear && todayMonth > month + 90 + 1) || (year === todayYear && month + 90 + 1 === todayMonth && todayDay > nextMonthDays.shift());

            days.push({
                "day": nextMonthDays.shift(),
                "month": month === 11 ? 0 : month + 1,
                "isOutOfRange": isOutOfRange // Next month's days are "out of range"
            });
        }

        return days;
    };

    // Navigate to the previous month
    const handlePreviousMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(currentDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    // Navigate to the next month
    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(currentDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    // Handle a date click and manage the range selection
    const handleDateClick = (year, month, day) => {
        if (!startDate) {
        // Select start date
        setInfo("startDate",new Date(year, month, day));
        setInfo("endDate",null);
        } else if (!endDate && new Date(year, month, day) >= startDate) {
        // Select end date only if it's after the start date
        setInfo("endDate",new Date(year, month, day));
        } else {
        // Reset the range selection if start date is reselected or end date is before start date
        setInfo("startDate",new Date(year, month, day));
        setInfo("endDate",null);
        }
    };

    // Get the current and next month
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const nextMonth = currentMonth + 1 > 11 ? 0 : currentMonth + 1;
    const nextMonthYear = nextMonth === 0 ? currentYear + 1 : currentYear;

    const currentMonthDays = getCalendarGrid(currentYear, currentMonth);
    const nextMonthDays = getCalendarGrid(nextMonthYear, nextMonth);
    console.log(currentMonthDays);
    console.log(nextMonthDays);

    // Helper function to check if a day is within the selected range
    const isDateInRange = (year, month, day) => {
        if (!startDate || !endDate) return false;
        const currentDay = new Date(year, month, day);
        return currentDay >= startDate && currentDay <= endDate;
    };

    return (
        <div className="calendar-picker">
        <div className="calendar-header">
            <button onClick={handlePreviousMonth}>&lt;</button>

            <div className="month-year">
            <span>{monthsList[currentMonth]} {currentYear}</span> &nbsp; / &nbsp;
            <span>{monthsList[nextMonth]} {nextMonthYear}</span>
            </div>

            <button onClick={handleNextMonth}>&gt;</button>
        </div>

        <div className="calendar-grid-wrapper">
            {/* Current Month */}
            <table className="calendar-grid-container">
            <thead>
                <tr>
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                    <th key={index} className="weekday">{day}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {[...Array(5)].map((_, rowIndex) => {
                const startIdx = rowIndex * 7;
                const endIdx = startIdx + 7;
                const currentMonthRowDays = currentMonthDays.slice(startIdx, endIdx);

                return (
                    <tr key={rowIndex}>
                        {currentMonthRowDays.map((day, colIndex) => {
                            const isWeekend = colIndex === 0 || colIndex === 6; // Sunday or Saturday
                            const isHoliday = isPublicHoliday(currentYear, day.month, day.day);
                            const isInRange = isDateInRange(currentYear, day.month, day.day);

                            const dayClass = `
                                day
                                ${day.isOutOfRange ? 'out-range' : ''}
                                ${isWeekend ? 'weekend' : ''}
                                ${isHoliday ? 'public-holiday' : ''}
                                ${isInRange && !isHoliday && !isWeekend ? 'in-range' : ''}
                                ${startDate?.getFullYear() === currentYear && startDate?.getMonth() === day.month && startDate?.getDate() === day.day ? 'start' : ''}
                                ${endDate?.getFullYear() === currentYear && endDate?.getMonth() === day.month && endDate?.getDate() === day.day ? 'end' : ''}
                            `;

                            return (
                                <td
                                    key={colIndex}
                                    className={dayClass}
                                    onClick={!day.isOutOfRange ? () => handleDateClick(currentYear, day.month, day.day) : undefined}
                                >
                                    {day.day}
                                </td>
                            );
                        })}
                    </tr>
                );
                })}
            </tbody>
            </table>

            {/* Next Month */}
            <table className="calendar-grid-container">
                <thead>
                    <tr>
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                        <th key={index} className="weekday">{day}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                {[...Array(5)].map((_, rowIndex) => {
                    let startIdx = 0;
                    console.log(currentMonthDays[28].day);
                    if(currentMonthDays[28].day === nextMonthDays[0].day){
                        startIdx = (rowIndex + 1) * 7;
                    }else{
                        startIdx = (rowIndex) * 7;
                    }
                    const endIdx = startIdx + 7;
                    const nextMonthRowDays = nextMonthDays.slice(startIdx, endIdx);

                return (
                    <tr key={rowIndex}>
                        {nextMonthRowDays.map((day, colIndex) => {
                            const isWeekend = colIndex === 0 || colIndex === 6; // Sunday or Saturday
                            const isHoliday = isPublicHoliday(nextMonthYear, day.month, day.day);
                            const isInRange = isDateInRange(nextMonthYear, day.month, day.day);
                            const dayClass = `
                                day
                                ${day.isOutOfRange ? 'out-range' : ''}
                                ${isWeekend ? 'weekend' : ''}
                                ${isHoliday ? 'public-holiday' : ''}
                                ${isInRange && !isHoliday && !isWeekend ? 'in-range' : ''}
                                ${startDate?.getFullYear() === currentYear && startDate?.getMonth() === day.month && startDate?.getDate() === day.day ? 'start' : ''}
                                ${endDate?.getFullYear() === currentYear && endDate?.getMonth() === day.month && endDate?.getDate() === day.day ? 'end' : ''}
                            `;

                            return (
                                <td
                                    key={colIndex}
                                    className={dayClass}
                                    onClick={!day.isOutOfRange ? () => handleDateClick(currentYear, day.month, day.day) : undefined}
                                >
                                    {day.day}
                                </td>
                            );
                            })}
                        </tr>
                    );
                })}
            </tbody>
            </table>
        </div>
        </div>
    );
};

export default CalendarPicker;

import React, { useState } from "react";
import SearchableDropdown from "../TicketComponents/SearchableDropdown";
import { useAuth } from "../../auth/AuthHandler";

// Helper function to adjust date to Singapore Time (SGT)
const convertToSGT = (date) => {
  const sgTimeZone = "Asia/Singapore";
  const options = { timeZone: sgTimeZone };

  // Convert date to SGT by creating a new Date object and using toLocaleString for time zone conversion
  const sgtDate = new Date(date.toLocaleString('en-US', options));

  // Return the SGT date
  return sgtDate;
};

// Helper function to format Date to yyyy-MM-dd for input field
const formatDateToInputValue = (date) => {
  if (!date) return ''; // Handle null or undefined date

  // Adjust date to SGT before formatting
  const sgtDate = convertToSGT(date);

  const year = sgtDate.getFullYear();
  const month = (sgtDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2 digits
  const day = sgtDate.getDate().toString().padStart(2, '0'); // Ensure 2 digits

  return `${year}-${month}-${day}`;
};

export default function LeaveRequestForm({ info, setInfo, handleRequestLeave, userList }) {
    const { expiration, authenticated, userEmail, userName, userRoles, handleLogout, handleLogin } = useAuth();
    const [errors, setErrors] = useState({ reason: '', startDate: '', endDate: '', category: ''});

    const validateFields = () => {
        const newErrors = {};
        let isValid = true;

        if(!info.startDate){
            newErrors.startDate = 'Start date is required';
            isValid = false;
        }

        if (!info.endDate) {
            newErrors.endDate = 'End date is required';
            isValid = false;
        }

        if (!info.reason) {
          newErrors.reason = 'Reason is required';
          isValid = false;
        }

        if (!info.category) {
          newErrors.category = 'Category is required';
          isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const formatDateToSGT = (date) => {
        const options = { timeZone: 'Asia/Singapore' };
        const singaporeDate = new Date(date.toLocaleString('en-US', options)); // Convert date to Singapore Time
        const year = singaporeDate.getFullYear();
        const month = (singaporeDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2 digits for month
        const day = singaporeDate.getDate().toString().padStart(2, '0'); // Ensure 2 digits for day
        return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
    };

    // Update startDate and reset endDate to null
    const handleStartDateChange = (e) => {
        const newStartDate = new Date(e.target.value);
        setInfo("startDate",convertToSGT(newStartDate)); // Convert to SGT before setting the state
        setInfo("endDate",null); // Reset endDate whenever startDate changes or is null
    };

    // Update endDate if it's valid (greater than or equal to startDate)
    const handleEndDateChange = (e) => {
        const newEndDate = new Date(e.target.value);
        if (newEndDate >= info.startDate) {
            setInfo("endDate",convertToSGT(newEndDate)); // Set end date if valid
        } else {
        alert("End date cannot be before the start date.");
        }
    };

    const handleRequestLeaveSubmit = async (e) =>{
        e.preventDefault();
        if(!validateFields()){return;}
        console.log(info);

        // Convert the startDate and endDate to Date objects
        const startDate = new Date(info.startDate);
        const endDate = new Date(info.endDate);

        // Adjust the dates to Singapore time (SGT)
        const formattedStartDate = formatDateToSGT(startDate);
        const formattedEndDate = formatDateToSGT(endDate);

        // Create an updated info object with the correct start and end dates
        const updatedInfo = {
            ...info,
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };

        console.log(updatedInfo);

        await handleRequestLeave(updatedInfo);
    }

    const handleSelectionChange = (selectedEmail) => {
        setInfo("email",selectedEmail);
    };

    return (
        <div className="leave-request-form">
            <h5>Leave request form</h5>
        <form>
            {/* Leave Category Section */}
            {['BOMANAGER', 'ADMIN'].some(role => userRoles.includes(role)) &&
            <div className='col'>
            <div className="mb-3 row">
                <label htmlFor="assign-to" className="col-sm-4 col-form-label">User Email:</label>
                <div className="col-sm-8">
                <SearchableDropdown
                id="aassign-to"
                options={userList}
                selectedValue={info.email}
                onSelectedChange={handleSelectionChange}
                placeholder="Select user"
                />
                </div>
            </div>
            </div>}

            <div className="col">
            <div className="mb-3 row">
                <label htmlFor="leaveCategory" className="col-sm-4 col-form-label">Leave Category:</label>
                <div className="col-sm-8">
                    <select
                        id="leaveCategory"
                        className={`form-select ${errors.reason ? 'is-invalid' : ''}`}
                        value={info.category || ""}
                        onChange={(e) => setInfo("category",e.target.value)}
                    >
                        <option value="">--- SELECT 1 ---</option>
                        {["ANNUAL LEAVE", "MEDICAL LEAVE", "HOSPITAL LEAVE"].map(
                        (listValue) => {
                            return (
                            <option key={listValue} value={listValue}>
                                {listValue}
                            </option>
                            );
                        }
                        )}
                    </select>
                    {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                </div>
            </div>
            </div>

            {/* Start Date Section */}
            <div className="col">
            <div className="mb-3 row">
                <label htmlFor="startDate" className="col-sm-4 col-form-label">
                Start date:
                </label>
                <div className="col-sm-8">
                    <input
                        type="date"
                        className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
                        id="startDate"
                        onChange={handleStartDateChange} // Use the new handler for start date
                        value={formatDateToInputValue(info.startDate)} // Format SGT date
                    />
                    {errors.startDate && <div className="invalid-feedback">{errors.startDate}</div>}
                </div>
            </div>
            </div>

            {/* End Date Section */}
            <div className="col">
            <div className="mb-3 row">
                <label htmlFor="endDate" className="col-sm-4 col-form-label">
                End date:
                </label>
                <div className="col-sm-8">
                    <input
                        type="date"
                        className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
                        id="endDate"
                        onChange={handleEndDateChange} // Use the new handler for end date
                        value={formatDateToInputValue(info.endDate)} // Format SGT date
                        min={formatDateToInputValue(info.startDate)} // Set minimum end date as start date
                    />
                    {errors.endDate && <div className="invalid-feedback">{errors.endDate}</div>}
                </div>
            </div>
            </div>

            {/* Reason Section */}
            <div className="mb-3 row">
            <label htmlFor="inputReason" className="col-sm-4 col-form-label">
                Reason:
            </label>
            <div className="col-sm-8">
                <textarea
                name="reason"
                className={`form-control ${errors.reason ? 'is-invalid' : ''}`}
                id="inputReason"
                rows="3"
                value={info.reason || ''}
                onChange={(e) => setInfo("reason",e.target.value)}
                />
                {errors.reason && <div className="invalid-feedback">{errors.reason}</div>}
            </div>
            </div>

            {/* Duration Section */}
            <div className="mb-3 row">
            <label htmlFor="duration" className="col-sm-4 col-form-label">
                Duration:
            </label>
            <div className="col-sm-8">
                <input
                name="duration"
                className={`form-control`}
                id="duration"
                value={info.duration || ''}
                disabled
                />
            </div>
            </div>

            <button type="button" className="btn btn-primary ms-2" onClick={handleRequestLeaveSubmit}>
                Request Leave
            </button>
        </form>
        </div>
    );
    }
